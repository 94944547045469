// eslint-disable-next-line import/prefer-default-export
export function clearCredentials() {
  // clear all storage and cookie
  // Some browsers seem to be missing localStorage and might throw when trying
  // to access the variable. Guard against this.
  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch {
    // Do nothing if storage is not supported in the browser
  }
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = `web_token=;expires=${new Date().toUTCString()};path=/`;
}
