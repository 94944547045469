import {
  AlertProps,
  ChakraProvider,
  extendTheme,
  theme as baseTheme,
} from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { ReactNode } from 'react';

const alertStyles = (color: string) => ({
  container: {
    background: 'unset',
    backgroundColor: `${color}.50`,
    borderColor: `${color}.800`,
    borderRadius: '4px',
    borderWidth: 1,
    color: `${color}.800`,
    fontSize: '14px',
    fontWeight: 700,
    padding: '8px',
  },
});

const Alert = {
  baseStyle: (props: AlertProps) => {
    const { status } = props;

    switch (status) {
      case 'error':
        return alertStyles('red');
      case 'success':
        return alertStyles('green');
      case 'warning':
        return alertStyles('yellow');
      default:
        return alertStyles('gray');
    }
  },
};

const Button = {
  baseStyle: {
    fontWeight: 700,
    borderRadius: '9999px',
  },
  defaultProps: {
    colorScheme: 'blue',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'sm',
      paddingX: baseTheme.space['7'],
    },
  },
  variants: {
    select: (props: StyleFunctionProps) => {
      const BaseButtonOutline =
        baseTheme.components.Button.variants.outline(props);

      return {
        ...BaseButtonOutline,
        borderRadius: baseTheme.radii.md,
        borderColor: 'gray.300',
        color: 'gray.500',
        fontWeight: 400,
        paddingX: baseTheme.space['4'],
        _active: {
          bg: 'white',
        },
        _focus: {
          bg: 'white',
        },
      };
    },
  },
};

const Input = {
  variants: {
    outline: {
      addon: {
        fontSize: 'sm',
      },
      field: {
        borderColor: 'gray.300',
        _hover: {
          bg: 'gray.50',
        },
        _focus: {
          boxShadow: '0 0 0 2px #9CD6F6',
          borderColor: 'gray.300',
          bg: 'white',
        },
        _invalid: {
          boxShadow: 'none',
          bg: 'red.50',
          color: 'red.500',
          _focus: {
            borderColor: 'red.500',
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: 'sm',
      },
    },
    md: {
      field: {
        fontSize: 'sm',
      },
    },
  },
};

const Checkbox = {
  baseStyle: {
    container: {
      fontWeight: 400,
      marginBottom: 0,
    },
  },
};

const Textarea = {
  variants: {
    outline: {
      _focus: {
        boxShadow: '0 0 0 2px #9CD6F6',
        borderColor: 'gray.300',
      },
      _invalid: {
        boxShadow: 'none',
        bg: 'red.50',
        color: 'red.500',
        _focus: {
          borderColor: 'red.500',
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: 'sm',
      },
    },
    md: {
      field: {
        fontSize: 'sm',
      },
    },
  },
};

export const theme = extendTheme({
  colors: {
    blue: {
      50: '#e5f2ff',
      100: '#c1d5f2',
      200: '#9bb8e3',
      300: '#759cd7',
      400: '#4f80ca',
      500: '#24477B',
      600: '#1b3964',
      700: '#0d223e',
      800: '#010b1b',
      900: '#010b1b',
    },
    brand: {
      sand: {
        light: '#F5F2EE',
        dark: '#E6DED3',
      },
    },
  },
  components: {
    Alert,
    Button,
    Checkbox,
    Input,
    Textarea,
  },
});

function ChakraThemeProvider({ children }: { children: ReactNode }) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ChakraThemeProvider;
