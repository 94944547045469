import { ThemeProvider } from '@emotion/react';
import { ReactNode } from 'react';

import colors from '../design-tokens/colors';
import spacers from '../design-tokens/spacers';
import textStyles from '../design-tokens/textStyles';

const fontsLegacy = {
  brand: "'BrixSansLight', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  light: "'BrixSansLight', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  extraLight:
    "'BrixSansExtraLight', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  regular: "'BrixSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif",
} as const;

const fontSizesLegacy = {
  xxs: '0.5rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xl2: '1.5rem',
  xl3: '1.875rem',
  xl4: '2rem',
  xl5: '2.25rem',
  xl6: '2.5rem',
  xl7: '3rem',
  xl8: '4rem',
} as const;

const colorsLegacy = {
  brand: '#a5accc',

  danger: '#f54c84',
  brandRed: '#ff7f74',

  brandGreen: '#95DA98',

  blue900: '#1f212e',
  blue800: '#404663',
  blue600: '#6f7acc',
  blue550: '#6e79cb',
  blue500: '#8992dd',
  blue400: '#8d97e4',
  blue100: '#e1e5f2',

  black900: '#24282e',
  black800: '#485465',
  black700: '#646980',
  black600: '#858aa4',
  black500: '#9297b0',
  black400: '#b0b3bf',
  black400HRM: '#9ea1b0',
  black300: '#c5cad3',
  black200: '#e9e9e9',
  black100: '#f7f8fc',

  grey300: '#E1E1E1',

  purple500: '#8992dd',

  green500: '#5acabd',

  prime: '#00d8ec',

  bgColor: '#fefefe',
  bgColorResearch: '#eff3ff',
} as const;

export const breakpoints = {
  xs: 375,

  // Small tablets and large smartphones (landscape view)
  sm: 576,

  // Small tablets (portrait view)
  md: 769,

  // Tablets and small desktops
  lg: 992,

  // Large tablets and desktops
  xl: 1200,
} as const;

const mediaQuery = {
  xs: `@media (min-width: ${breakpoints.xs}px)`,

  // Small tablets and large smartphones (landscape view)
  sm: `@media (min-width: ${breakpoints.sm}px)`,

  // Small tablets (portrait view)
  md: `@media (min-width: ${breakpoints.md}px)`,

  // Tablets and small desktops
  lg: `@media (min-width: ${breakpoints.lg}px)`,

  // Large tablets and desktops
  xl: `@media (min-width: ${breakpoints.xl}px)`,
} as const;

export const spacingLegacy = {
  xs: '0.5rem',
  none: '0rem',
  xxs: '0.25rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  xl2: '2rem',
  xl3: '3rem',
  xl4: '6rem',

  gutter: '1.5rem',
} as const;

const transitions = {
  fast: '100ms ease-in-out',
  default: '200ms ease-in-out',
};

const boxShadows = {
  focusRing: `0 0 0 4px ${colors.blueLight30}`,
  hover:
    '-8px -8px 24px rgba(255, 255, 255, 0.75), -1px -1px 1px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(190, 199, 211, 0.5)',
};

const elements = {
  topBarMobileHeight: '3.125rem',
  topBarDesktopHeight: '5rem',
} as const;

const theme = {
  fontsLegacy,
  fontSizesLegacy,
  spacingLegacy,
  colorsLegacy,
  mediaQuery,
  colors,
  spacers,
  textStyles,
  transitions,
  boxShadows,
  elements,
} as const;

type OuraTheme = typeof theme;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends OuraTheme {}

  export function useTheme(): OuraTheme;
}

export function OuraThemeProvider({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
