import { IntlShape } from 'react-intl';

import { MessageKey } from '../localization/setup';
import defaults from '../specs/defaultMeasurements.json';

export type Measurement =
  | 'score'
  | 'ratio'
  | 'energy'
  | 'met'
  | 'step'
  | 'count'
  | 'duration'
  | 'datetime'
  | 'time_of_day'
  | 'weight'
  | 'length'
  | 'temperature_delta'
  | 'breath_rate'
  | 'heart_rate'
  | 'heart_rate_variability';

export interface Metric {
  title: string;
  localization_key: MessageKey;
  measurement: Measurement;
  data_unit: string;
  chart_unit?: string | LocalizedUnit;
  display_unit: string | LocalizedUnit;
  display_format?: string;
  hidden?: boolean;
  tab?: 'readiness' | 'longest_sleep' | 'activity';
}

export interface LocalizedUnit {
  metric: string;
  imperial: string;
}

export const metrics = {
  'daily_sleep.score': {
    ...defaults.score,
    key: 'daily_sleep.score',
    title: 'Sleep Score',
    localization_key: 'general_sleep_score',
    measurement: 'score',
  },
  'daily_sleep.contributors.total_sleep': {
    ...defaults.score,
    key: 'daily_sleep.contributors.total_sleep',
    title: 'Sleep Score: Total',
    localization_key: 'general_sleep_score_total',
    measurement: 'score',
  },
  'daily_sleep.contributors.rem_sleep': {
    ...defaults.score,
    key: 'daily_sleep.contributors.rem_sleep',
    title: 'Sleep Score: REM',
    localization_key: 'general_sleep_score_rem',
    measurement: 'score',
  },
  'daily_sleep.contributors.deep_sleep': {
    ...defaults.score,
    key: 'daily_sleep.contributors.deep_sleep',
    title: 'Sleep Score: Deep',
    localization_key: 'general_sleep_score_deep',
    measurement: 'score',
  },
  'daily_sleep.contributors.efficiency': {
    ...defaults.score,
    key: 'daily_sleep.contributors.efficiency',
    title: 'Sleep Score: Efficiency',
    localization_key: 'general_sleep_score_efficiency',
    measurement: 'score',
  },
  'daily_sleep.contributors.restfulness': {
    ...defaults.score,
    key: 'daily_sleep.contributors.restfulness',
    title: 'Sleep Score: Restfulness',
    localization_key: 'general_sleep_score_restfulness',
    measurement: 'score',
  },
  'daily_sleep.contributors.latency': {
    ...defaults.score,
    key: 'daily_sleep.contributors.latency',
    title: 'Sleep Score: Latency',
    localization_key: 'general_sleep_score_latency',
    measurement: 'score',
  },
  'daily_sleep.contributors.timing': {
    ...defaults.score,
    key: 'daily_sleep.contributors.timing',
    title: 'Sleep Score: Timing',
    localization_key: 'general_sleep_score_timing',
    measurement: 'score',
  },
  'sleep.score': {
    ...defaults.score,
    key: 'sleep.score',
    title: 'Sleep Score',
    localization_key: 'general_sleep_score',
    measurement: 'score',
  },
  'sleep.contributors.total_sleep': {
    ...defaults.score,
    key: 'sleep.contributors.total_sleep',
    title: 'Sleep Score: Total',
    localization_key: 'general_sleep_score_total',
    measurement: 'score',
  },
  'sleep.contributors.rem_sleep': {
    ...defaults.score,
    key: 'sleep.contributors.rem_sleep',
    title: 'Sleep Score: REM',
    localization_key: 'general_sleep_score_rem',
    measurement: 'score',
  },
  'sleep.contributors.deep_sleep': {
    ...defaults.score,
    key: 'sleep.contributors.deep_sleep',
    title: 'Sleep Score: Deep',
    localization_key: 'general_sleep_score_deep',
    measurement: 'score',
  },
  'sleep.contributors.efficiency': {
    ...defaults.score,
    key: 'sleep.contributors.efficiency',
    title: 'Sleep Score: Efficiency',
    localization_key: 'general_sleep_score_efficiency',
    measurement: 'score',
  },
  'sleep.contributors.restfulness': {
    ...defaults.score,
    key: 'sleep.contributors.restfulness',
    title: 'Sleep Score: Restfulness',
    localization_key: 'general_sleep_score_restfulness',
    measurement: 'score',
  },
  'sleep.contributors.latency': {
    ...defaults.score,
    key: 'sleep.contributors.latency',
    title: 'Sleep Score: Latency',
    localization_key: 'general_sleep_score_latency',
    measurement: 'score',
  },
  'sleep.contributors.timing': {
    ...defaults.score,
    key: 'sleep.contributors.timing',
    title: 'Sleep Score: Timing',
    localization_key: 'general_sleep_score_timing',
    measurement: 'score',
  },
  'sleep.time_in_bed': {
    ...defaults.duration,
    key: 'sleep.time_in_bed',
    title: 'Time in bed',
    localization_key: 'general_time_in_bed',
    measurement: 'duration',
  },
  'sleep.total_sleep_duration': {
    ...defaults.duration,
    key: 'sleep.total_sleep_duration',
    title: 'Total Sleep',
    localization_key: 'general_abbreviation_total_sleep_time',
    measurement: 'duration',
  },
  'sleep.awake_time': {
    ...defaults.duration,
    key: 'sleep.awake_time',
    title: 'Awake Time',
    localization_key: 'general_awake_time',
    measurement: 'duration',
  },
  'sleep.rem_sleep_duration': {
    ...defaults.duration,
    key: 'sleep.rem_sleep_duration',
    title: 'REM Sleep Time',
    localization_key: 'general_rem_sleep',
    measurement: 'duration',
  },
  'sleep.light_sleep_duration': {
    ...defaults.duration,
    key: 'sleep.light_sleep_duration',
    title: 'Light Sleep Time',
    localization_key: 'general_light_sleep',
    measurement: 'duration',
  },
  'sleep.deep_sleep_duration': {
    ...defaults.duration,
    key: 'sleep.deep_sleep_duration',
    title: 'Deep Sleep Time',
    localization_key: 'general_deep_sleep',
    measurement: 'duration',
  },
  'sleep.restless_periods': {
    ...defaults.ratio,
    key: 'sleep.restless_periods',
    title: 'Restless Sleep',
    localization_key: 'general_restless_sleep',
    measurement: 'ratio',
  },
  'sleep.efficiency': {
    ...defaults.ratio,
    key: 'sleep.efficiency',
    title: 'Sleep Efficiency',
    localization_key: 'general_sleep_efficiency',
    measurement: 'ratio',
  },
  'sleep.latency': {
    ...defaults.duration,
    key: 'sleep.latency',
    title: 'Sleep Latency',
    localization_key: 'general_sleep_latency',
    measurement: 'duration',
    display_unit: 'minute',
    chart_unit: 'minute',
    display_format: 'd',
  },
  'sleep.sleep_midpoint': {
    ...defaults.duration,
    key: 'sleep.sleep_midpoint',
    title: 'Sleep Timing',
    localization_key: 'general_sleep_timing',
    measurement: 'duration',
    data_unit: 'second',
    chart_unit: 'hour',
    display_unit: 'hour_minute',
  },
  /** Added in conversion phase */
  'sleep.midpoint_at_delta': {
    ...defaults.time_of_day,
    key: 'sleep.midpoint_at_delta',
    title: 'Midpoint',
    localization_key: 'general_abbreviation_midpoint',
    measurement: 'time_of_day',
    data_unit: 'second',
  },
  'sleep.bedtime_start': {
    ...defaults.datetime,
    key: 'sleep.bedtime_start',
    title: 'Bedtime',
    localization_key: 'general_bedtime',
    measurement: 'datetime',
  },
  'sleep.bedtime_end': {
    ...defaults.datetime,
    key: 'sleep.bedtime_end',
    title: 'Wake-up time',
    localization_key: 'general_wake_up_time',
    measurement: 'datetime',
  },
  /** Added in conversion phase */
  'sleep.bedtime_start_delta': {
    ...defaults.time_of_day,
    key: 'sleep.bedtime_start_delta',
    title: 'Bedtime',
    localization_key: 'general_bedtime',
    measurement: 'time_of_day',
  },
  /** Added in conversion phase */
  'sleep.bedtime_end_delta': {
    ...defaults.time_of_day,
    key: 'sleep.bedtime_end_delta',
    title: 'Wake-up time',
    localization_key: 'general_wake_up_time',
    measurement: 'time_of_day',
  },
  'sleep.average_heart_rate': {
    ...defaults.heart_rate,
    key: 'sleep.average_heart_rate',
    title: 'Average Resting HR',
    localization_key: 'general_average_resting_hr',
    measurement: 'heart_rate',
  },
  'sleep.lowest_heart_rate': {
    ...defaults.heart_rate,
    key: 'sleep.lowest_heart_rate',
    title: 'Lowest Resting HR',
    localization_key: 'general_lowest_resting_hr',
    measurement: 'heart_rate',
    tab: 'readiness',
  },
  'sleep.average_hrv': {
    ...defaults.heart_rate_variability,
    key: 'sleep.average_hrv',
    title: 'Average HRV',
    localization_key: 'general_average_hrv',
    measurement: 'heart_rate_variability',
    tab: 'readiness',
  },
  'sleep.average_breath': {
    ...defaults.breath_rate,
    key: 'sleep.average_breath',
    title: 'Respiratory Rate',
    localization_key: 'general_respiratory_rate',
    measurement: 'breath_rate',
  },
  'daily_activity.score': {
    ...defaults.score,
    key: 'daily_activity.score',
    title: 'Activity Score',
    localization_key: 'general_activity_score',
    measurement: 'score',
  },
  'daily_activity.contributors.stay_active': {
    ...defaults.score,
    key: 'daily_activity.contributors.stay_active',
    title: 'Activity Score: Inactive Time',
    localization_key: 'general_activity_score_inactive_time',
    measurement: 'score',
  },
  'daily_activity.contributors.move_every_hour': {
    ...defaults.score,
    key: 'daily_activity.contributors.move_every_hour',
    title: 'Activity Score: Move Every Hour',
    localization_key: 'general_activity_score_move_every_hour',
    measurement: 'score',
  },
  'daily_activity.contributors.meet_daily_targets': {
    ...defaults.score,
    key: 'daily_activity.contributors.meet_daily_targets',
    title: 'Activity Score: Meet Daily Targets',
    localization_key: 'general_activity_score_meet_daily_targets',
    measurement: 'score',
  },
  'daily_activity.contributors.training_frequency': {
    ...defaults.score,
    key: 'daily_activity.contributors.training_frequency',
    title: 'Activity Score: Training Frequency',
    localization_key: 'general_activity_score_training_frequency',
    measurement: 'score',
  },
  'daily_activity.contributors.training_volume': {
    ...defaults.score,
    key: 'daily_activity.contributors.training_volume',
    title: 'Activity Score: Training Volume',
    localization_key: 'general_activity_score_training_volume',
    measurement: 'score',
  },
  'daily_activity.contributors.recovery_time': {
    ...defaults.score,
    key: 'daily_activity.contributors.recovery_time',
    title: 'Activity Score: Recovery Time',
    localization_key: 'general_activity_score_recovery_time',
    measurement: 'score',
  },
  'daily_activity.active_calories': {
    ...defaults.energy,
    key: 'daily_activity.active_calories',
    title: 'Activity Burn',
    localization_key: 'general_activity_burn',
    measurement: 'energy',
  },
  'daily_activity.total_calories': {
    ...defaults.energy,
    key: 'daily_activity.total_calories',
    title: 'Total Burn',
    localization_key: 'general_total_burn',
    measurement: 'energy',
  },
  'daily_activity.target_calories': {
    ...defaults.energy,
    key: 'daily_activity.target_calories',
    title: 'Target Burn',
    localization_key: 'general_target_burn',
    measurement: 'energy',
  },
  'daily_activity.steps': {
    ...defaults.step,
    key: 'daily_activity.steps',
    title: 'Steps',
    localization_key: 'general_steps',
    measurement: 'step',
  },
  'daily_activity.equivalent_walking_distance': {
    ...defaults.length,
    key: 'daily_activity.equivalent_walking_distance',
    title: 'Walking Equivalent',
    localization_key: 'general_walking_equivalency',
    measurement: 'length',
    data_unit: 'meter',
  },
  'daily_activity.sedentary_time': {
    ...defaults.duration,
    key: 'daily_activity.sedentary_time',
    title: 'Inactive',
    localization_key: 'general_abbreviation_inactive_time',
    measurement: 'duration',
  },
  'daily_activity.resting_time': {
    ...defaults.duration,
    key: 'daily_activity.resting_time',
    title: 'Resting Time',
    localization_key: 'general_resting_time',
    measurement: 'duration',
  },
  'daily_activity.low_activity_time': {
    ...defaults.duration,
    key: 'daily_activity.low_activity_time',
    title: 'Low Activity',
    localization_key: 'general_low_activity',
    measurement: 'duration',
  },
  'daily_activity.medium_activity_time': {
    ...defaults.duration,
    key: 'daily_activity.medium_activity_time',
    title: 'Medium Activity',
    localization_key: 'general_medium_activity',
    measurement: 'duration',
  },
  'daily_activity.high_activity_time': {
    ...defaults.duration,
    key: 'daily_activity.high_activity_time',
    title: 'High Activity',
    localization_key: 'general_high_activity',
    measurement: 'duration',
    chart_unit: 'minute',
    display_unit: 'minute',
  },
  'daily_activity.non_wear_time': {
    ...defaults.duration,
    key: 'daily_activity.non_wear_time',
    title: 'Non-wear Time',
    localization_key: 'general_non_wear_time',
    measurement: 'duration',
  },
  'daily_activity.average_met_minutes': {
    ...defaults.met,
    key: 'daily_activity.average_met_minutes',
    title: 'Average METs',
    localization_key: 'general_average_met',
    measurement: 'met',
  },
  'daily_activity.inactivity_alerts': {
    ...defaults.count,
    key: 'daily_activity.inactivity_alerts',
    title: 'Long Periods of Inactivity',
    localization_key: 'general_long_periods_of_inactivity',
    measurement: 'count',
    display_unit: 'warning',
  },
  'daily_readiness.score': {
    ...defaults.score,
    key: 'daily_readiness.score',
    title: 'Readiness Score',
    localization_key: 'general_readiness_score',
    measurement: 'score',
  },
  'daily_readiness.contributors.previous_night': {
    ...defaults.score,
    key: 'daily_readiness.contributors.previous_night',
    title: 'Readiness Score: Sleep',
    localization_key: 'general_readiness_score_previous_night',
    measurement: 'score',
  },
  'daily_readiness.contributors.sleep_balance': {
    ...defaults.score,
    key: 'daily_readiness.contributors.sleep_balance',
    title: 'Readiness Score: Sleep Balance',
    localization_key: 'general_readiness_score_sleep_balance',
    measurement: 'score',
  },
  'daily_readiness.contributors.previous_day_activity': {
    ...defaults.score,
    key: 'daily_readiness.contributors.previous_day_activity',
    title: 'Readiness Score: Previous Day Activity',
    localization_key: 'general_readiness_score_previous_day_activity',
    measurement: 'score',
  },
  'daily_readiness.contributors.activity_balance': {
    ...defaults.score,
    key: 'daily_readiness.contributors.activity_balance',
    title: 'Readiness Score: Activity Balance',
    localization_key: 'general_readiness_score_activity_balance',
    measurement: 'score',
  },
  'daily_readiness.contributors.body_temperature': {
    ...defaults.score,
    key: 'daily_readiness.contributors.body_temperature',
    title: 'Readiness Score: Temperature',
    localization_key: 'general_readiness_score_temperature',
    measurement: 'score',
  },
  'daily_readiness.contributors.resting_heart_rate': {
    ...defaults.score,
    key: 'daily_readiness.contributors.resting_heart_rate',
    title: 'Readiness Score: Resting Heart Rate',
    localization_key: 'general_readiness_score_resting_heart_rate',
    measurement: 'score',
  },
  'daily_readiness.contributors.hrv_balance': {
    ...defaults.score,
    key: 'daily_readiness.contributors.hrv_balance',
    title: 'Readiness Score: HRV Balance',
    localization_key: 'general_readiness_score_hrv_balance',
    measurement: 'score',
  },
  'daily_readiness.contributors.recovery_index': {
    ...defaults.score,
    key: 'daily_readiness.contributors.recovery_index',
    title: 'Readiness Score: Recovery Index',
    localization_key: 'general_readiness_score_recovery_index',
    measurement: 'score',
  },
  'daily_readiness.temperature_deviation': {
    ...defaults.temperature_delta,
    key: 'daily_readiness.temperature_deviation',
    title: 'Temperature Deviation',
    localization_key: 'general_temperature_deviation',
    measurement: 'temperature_delta',
    display_format: '+.1f',
  },
  'daily_readiness.temperature_trend_deviation': {
    ...defaults.temperature_delta,
    key: 'daily_readiness.temperature_trend_deviation',
    title: 'Temperature Trend Deviation',
    localization_key: 'general_temperature_trend_deviation',
    measurement: 'temperature_delta',
    display_format: '+.1f',
  },
  'daily_spo2_average.spo2_percentage': {
    ...defaults.ratio,
    key: 'daily_spo2_average.spo2_percentage',
    title: 'Average Oxygen Saturation',
    localization_key: 'general_daily_spo2_spo2_average',
    measurement: 'ratio',
  },
} as const;

export type MetricKey = keyof typeof metrics;

export function isMetricKey(key: unknown): key is MetricKey {
  return typeof key === 'string' && metrics.hasOwnProperty(key);
}

export function getMetricTitle(intl: IntlShape, key: MetricKey): string {
  return intl.formatMessage({ id: getMetricTitleLocalizationKey(key) });
}

export function getMetricTitleLocalizationKey(key: MetricKey): MessageKey {
  return metrics[key].localization_key;
}

export type MetricCategory = 'readiness' | 'longest_sleep' | 'activity';

export function getMetricCategory(key: MetricKey): MetricCategory {
  const metric: Metric = metrics[key];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return metric.tab || (key.split('.')[0] as MetricCategory);
}

const SCORE_METRICS = new Set<MetricKey>([
  'daily_readiness.score',
  'daily_activity.score',
  'daily_sleep.score',
]);

export function isScoreMetricKey(key: string) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return SCORE_METRICS.has(key as MetricKey);
}

export const shownKeys: MetricKey[] = [
  'daily_sleep.score',
  'sleep.total_sleep_duration',
  'sleep.time_in_bed',
  'sleep.awake_time',
  'sleep.rem_sleep_duration',
  'sleep.light_sleep_duration',
  'sleep.deep_sleep_duration',
  'sleep.efficiency',
  'sleep.latency',
  'sleep.bedtime_start_delta',
  'sleep.bedtime_end_delta',
  'sleep.midpoint_at_delta',
  'sleep.average_heart_rate',
  'sleep.average_breath',
  'daily_activity.score',
  'daily_activity.active_calories',
  'daily_activity.total_calories',
  'daily_activity.steps',
  'daily_activity.equivalent_walking_distance',
  'daily_activity.inactivity_alerts',
  'daily_activity.sedentary_time',
  'daily_activity.resting_time',
  'daily_activity.low_activity_time',
  'daily_activity.medium_activity_time',
  'daily_activity.high_activity_time',
  'daily_activity.non_wear_time',
  'daily_activity.average_met_minutes',
  'daily_readiness.score',
  'sleep.lowest_heart_rate',
  'sleep.average_hrv',
  'daily_readiness.temperature_deviation',
  'daily_readiness.temperature_trend_deviation',
  'daily_spo2_average.spo2_percentage',
];

function getSorter(intl: IntlShape) {
  return function sortByScoreAndTitle(aKey: MetricKey, bKey: MetricKey) {
    const aIsScore = isScoreMetricKey(aKey);
    const bIsScore = isScoreMetricKey(bKey);

    if (aIsScore && !bIsScore) {
      return -1;
    }

    if (!aIsScore && bIsScore) {
      return 1;
    }

    return getMetricTitle(intl, aKey) > getMetricTitle(intl, bKey) ? 1 : -1;
  };
}

export function getMetricKeysSorted(intl: IntlShape): MetricKey[] {
  return [...shownKeys].sort(getSorter(intl));
}

export const exportableMetrics: MetricKey[] = [
  'daily_sleep.score',
  'daily_sleep.contributors.total_sleep',
  'daily_sleep.contributors.rem_sleep',
  'daily_sleep.contributors.deep_sleep',
  'daily_sleep.contributors.efficiency',
  'daily_sleep.contributors.restfulness',
  'daily_sleep.contributors.latency',
  'daily_sleep.contributors.timing',
  'sleep.total_sleep_duration',
  'sleep.time_in_bed',
  'sleep.awake_time',
  'sleep.rem_sleep_duration',
  'sleep.light_sleep_duration',
  'sleep.deep_sleep_duration',
  'sleep.restless_periods',
  'sleep.efficiency',
  'sleep.latency',
  'sleep.sleep_midpoint',
  'sleep.bedtime_start',
  'sleep.bedtime_end',
  'sleep.average_heart_rate',
  'sleep.lowest_heart_rate',
  'sleep.average_hrv',
  'daily_readiness.temperature_deviation',
  'daily_readiness.temperature_trend_deviation',
  'sleep.average_breath',
  'daily_activity.score',
  'daily_activity.contributors.stay_active',
  'daily_activity.contributors.move_every_hour',
  'daily_activity.contributors.meet_daily_targets',
  'daily_activity.contributors.training_frequency',
  'daily_activity.contributors.training_volume',
  'daily_activity.active_calories',
  'daily_activity.total_calories',
  'daily_activity.steps',
  'daily_activity.equivalent_walking_distance',
  'daily_activity.sedentary_time',
  'daily_activity.resting_time',
  'daily_activity.low_activity_time',
  'daily_activity.medium_activity_time',
  'daily_activity.high_activity_time',
  'daily_activity.non_wear_time',
  'daily_activity.average_met_minutes',
  'daily_activity.inactivity_alerts',
  'daily_readiness.score',
  'daily_readiness.contributors.previous_night',
  'daily_readiness.contributors.sleep_balance',
  'daily_readiness.contributors.previous_day_activity',
  'daily_readiness.contributors.activity_balance',
  'daily_readiness.contributors.body_temperature',
  'daily_readiness.contributors.resting_heart_rate',
  'daily_readiness.contributors.hrv_balance',
  'daily_readiness.contributors.recovery_index',
];

export const SCORE_CATEGORY_OPTIMAL_LOWER_BOUND = 85;
export const SCORE_CATEGORY_GOOD_LOWER_BOUND = 70;
export const SCORE_CATEGORY_FAIR_LOWER_BOUND = 60;
