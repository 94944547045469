import styled from '@emotion/styled';
import { FallbackProps } from 'react-error-boundary';

import errorBoundaryImageDeskTop from '../../../../images/ErrorBoundaryDesktop.png';
import errorBoundaryImageMobile from '../../../../images/ErrorBoundaryMobile.png';
import { breakpoints } from '../../../common/styles/theme';
import useWindowWidth from '../../useWindowWidth';
import AppError from './utils';

const BackgroundContainer = styled.div`
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.elements.topBarMobileHeight})`};
  background-color: ${({ theme }) => theme.colors.blueDark100};
  z-index: 0;
  position: relative;
  overflow: hidden;
  top: 0;
  width: 100vw;

  ${({ theme }) => theme.mediaQuery.md} {
    min-height: ${({ theme }) =>
      `calc(100vh - ${theme.elements.topBarDesktopHeight})`};
    width: calc(100vw - var(--sidebar-width));
  }
`;

const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.blueDark10};
  padding-top: ${({ theme }) => theme.spacers.l};
  padding-right: ${({ theme }) => theme.spacers.s};
  padding-bottom: 0px;
  padding-left: ${({ theme }) => theme.spacers.s};

  ${({ theme }) => theme.mediaQuery.sm} {
    padding-left: ${({ theme }) => theme.spacers.xxl};
    padding-top: 200px;
  }
`;

const ErrorImageDesktop = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - var(--sidebar-width));
  height: ${({ theme }) =>
    `calc(100vh - ${theme.elements.topBarDesktopHeight})`};
  overflow: visible;
  z-index: -1;
  object-fit: contain;
`;

const ErrorImageMobile = styled.img`
  position: absolute;
  width: 100%;
  height: calc(100vh + ${({ theme }) => theme.elements.topBarMobileHeight});
  bottom: 0;
  z-index: -1;
  width: 100%;
  object-fit: contain;
`;

const Heading = styled.h2`
  ${({ theme }) => theme.textStyles.scoreS};
  color: ${({ theme }) => theme.colors.monochrome10};
  padding: 0;
  margin: 0;
`;

const ParagraphContainer = styled.section`
  ${({ theme }) => theme.textStyles.paragraphM};
  color: ${({ theme }) => theme.colors.monochrome10};
  margin: ${({ theme: { spacers } }) => `${spacers.m} 0 ${spacers.l}`};
  max-width: 420px;
`;

const Button = styled.button`
  ${({ theme }) => theme.textStyles.labelL};
  border: 1.4px solid ${({ theme }) => theme.colors.monochrome10};
  color: ${({ theme }) => theme.colors.monochrome10};
  background: ${({ theme }) => theme.colors.blueDark100};
  border-radius: 43.5px;
  box-shadow: none;
  margin: 0 auto;
  display: block;
  text-transform: capitalize;
  width: 160px;
  height: 48px;
  cursor: pointer;
  outline: none;

  ${({ theme }) => theme.mediaQuery.sm} {
    margin-left: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blueDark100};
    background: ${({ theme }) => theme.colors.monochrome10};
  }

  &:active {
    color: ${({ theme }) => theme.colors.blueDark90};
  }
`;

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const isLargeScreen = useWindowWidth() > breakpoints.xl;
  const isAppError = error instanceof AppError;

  return (
    <BackgroundContainer>
      <TextContainer>
        <Heading>Something went wrong.</Heading>

        <ParagraphContainer>
          {isAppError && <p>{error.message}</p>}

          <p>
            Don&apos;t worry, we&apos;re on the case! Try refreshing or
            navigating to another page.
          </p>
        </ParagraphContainer>

        <Button onClick={resetErrorBoundary}>Refresh</Button>
      </TextContainer>

      {isLargeScreen ? (
        <ErrorImageDesktop src={errorBoundaryImageDeskTop} alt="" />
      ) : (
        <ErrorImageMobile src={errorBoundaryImageMobile} alt="" />
      )}
    </BackgroundContainer>
  );
}
