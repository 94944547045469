import { groupBy } from 'lodash';

import {
  ReceiveDailyDataAction,
  RequestDailyDataAction,
} from '../actions/types';
import {
  DailyActivity,
  DailyReadiness,
  DailySleep,
  DailySpO2Average,
  GetDailyDataResponse,
  Sleep,
} from '../api/types';
import { DailyData } from '../common/types';
import {
  handleReceiveData,
  handleRequestData,
} from './dataByRangeReducerHelpers';
import { DailyDataByDateState } from './types';

export const initialDailyDataByDateState: DailyDataByDateState = {
  data: {},
  fetchStatus: {
    type: 'not-fetched',
  },
};

export function handleRequestDailyData<
  A extends Omit<RequestDailyDataAction, 'type'>,
>(state: DailyDataByDateState, action: A): DailyDataByDateState {
  return handleRequestData(state, action);
}

const THREE_HOURS_IN_SECONDS = 60 * 60 * 3;
function getLongestSleep(sleeps: Sleep[] | undefined): Sleep | undefined {
  const longest = sleeps?.sort(
    (a, b) => (b.total_sleep_duration ?? 0) - (a.total_sleep_duration ?? 0),
  )[0];
  return (longest?.total_sleep_duration ?? 0) >= THREE_HOURS_IN_SECONDS
    ? longest
    : undefined;
}

function updateDailyData({
  sleeps,
  daily_activities,
  daily_readinesses,
  daily_sleeps,
  daily_spo2_averages,
}: GetDailyDataResponse) {
  const result: { [date: string]: DailyData } = {};

  function updateDailyRecord(
    day: string,
    [key, value]:
      | [key: 'sleeps', value: Sleep[]]
      | [key: 'daily_activity', value: DailyActivity]
      | [key: 'daily_readiness', value: DailyReadiness]
      | [key: 'daily_sleep', value: DailySleep]
      | [key: 'longest_sleep', value: Sleep | undefined]
      | [key: 'daily_spo2_average', value: DailySpO2Average],
  ) {
    result[day] = {
      ...result[day],
      date: day,
      [key]: value,
    };
  }

  Object.entries(groupBy(sleeps, 'day')).forEach(([day, daySleeps]) => {
    updateDailyRecord(day, ['sleeps', daySleeps]);
    updateDailyRecord(day, ['longest_sleep', getLongestSleep(daySleeps)]);
  });
  daily_activities.forEach((data) => {
    updateDailyRecord(data.day, ['daily_activity', data]);
  });
  daily_readinesses.forEach((data) => {
    updateDailyRecord(data.day, ['daily_readiness', data]);
  });
  daily_sleeps.forEach((data) => {
    updateDailyRecord(data.day, ['daily_sleep', data]);
  });
  daily_spo2_averages.forEach((data) => {
    updateDailyRecord(data.day, ['daily_spo2_average', data]);
  });

  return result;
}

export function handleReceiveDailyData<
  A extends Omit<ReceiveDailyDataAction, 'type'>,
>(state: DailyDataByDateState, action: A): DailyDataByDateState {
  return handleReceiveData(state, action, updateDailyData);
}
