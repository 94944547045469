/* eslint-disable @typescript-eslint/default-param-last */
import { subMonths } from 'date-fns';
import { fromPairs, omit } from 'lodash';
import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';

import { Action } from '../actions/types';
import { toQueryState } from '../api/converters/personal';
import { getToday, toDateString } from '../common/dates';
import * as modalIDs from '../common/modalIDs';
import {
  handleReceiveDailyData,
  handleRequestDailyData,
  initialDailyDataByDateState,
} from './dailyData';
import research from './researchReducers';
import teams from './teamsReducers';
import {
  AppState,
  DailyDataByDateState,
  ModalState,
  RootState,
  TrendsViewSettingsState,
} from './types';

function dailyDataByDate(
  state = initialDailyDataByDateState,
  action: Action,
): DailyDataByDateState {
  switch (action.type) {
    case 'REQUEST_DAILY_DATA': {
      return handleRequestDailyData(state, action);
    }
    case 'RECEIVE_DAILY_DATA':
      return handleReceiveDailyData(state, action);
    default:
      return state;
  }
}

const initialStateForTrendsView: TrendsViewSettingsState = {
  dateRange: {
    start: toDateString(subMonths(getToday(), 2)),
    end: toDateString(getToday()),
  },
};

function trendsViewSettings(
  state = initialStateForTrendsView,
  action: Action,
): TrendsViewSettingsState {
  switch (action.type) {
    case REHYDRATE: {
      const persistedState = action.payload.trendsViewSettings;

      if (!persistedState) {
        return state;
      }

      // When rehydrating, don't restore query settings. They are fetched from
      // the server. Only persist the selected date range.
      return {
        ...state,
        dateRange: persistedState.dateRange,
      };
    }

    case 'ADD_CHART':
      if (!state.query) {
        return state;
      }

      return {
        ...state,
        query: {
          ...state.query,
          subqueries: [
            ...state.query.subqueries,
            { key: action.key, y1: action.key },
          ],
          setLocally: true,
        },
      };

    case 'REMOVE_CHART': {
      if (!state.query) {
        return state;
      }

      const { index, subIndex } = action;

      if (subIndex === 'y2') {
        return {
          ...state,
          query: {
            ...state.query,
            subqueries: state.query.subqueries.map((subquery, i) => {
              if (i === index) {
                return omit(subquery, 'y2');
              }
              return subquery;
            }),
            setLocally: true,
          },
        };
      }

      return {
        ...state,
        query: {
          ...state.query,
          subqueries: state.query.subqueries.filter(
            (_subquery, i) => i !== index,
          ),
          setLocally: true,
        },
      };
    }

    case 'CHANGE_QUERY_KEY': {
      if (!state.query) {
        return state;
      }

      const { index, type, ...rest } = action;
      return {
        ...state,
        query: {
          ...state.query,
          subqueries: state.query.subqueries.map((subquery, i) => {
            if (i === index) {
              return {
                ...subquery,
                ...rest,
              };
            }
            return subquery;
          }),
          setLocally: true,
        },
      };
    }

    case 'SELECT_DATE_RANGE': {
      const { start, end } = action.range;

      return {
        ...state,
        dateRange: { start: toDateString(start), end: toDateString(end) },
      };
    }

    case 'SELECT_TIME_AGGREGATION':
      if (!state.query) {
        return state;
      }

      return {
        ...state,
        query: {
          ...state.query,
          timeAggregation: action.aggregation,
          setLocally: true,
        },
      };

    case 'RECEIVE_TRENDS_SETTINGS':
    case 'POST_TRENDS_SETTINGS_SUCCESS':
      return {
        ...state,
        query: toQueryState(action.payload),
      };

    default:
      return state;
  }
}

// If we make a change to the shape of the state tree for reducers that are
// persisted (see REDUCERS_TO_NOT_PERSIST in AppProvider), we should change this
// string in order to bust the cached Redux state trees.
export const STATE_VERSION = '0.9.2';

const initialAppState: AppState = {
  version: process.env.RELEASE_VERSION,
  stateVersion: STATE_VERSION,
};

function app(state = initialAppState, _action: Action): AppState {
  return state;
}

const initialModalState: ModalState = fromPairs(
  modalIDs.all.map((modalID) => [
    modalID,
    { id: modalID, isVisible: false, error: null },
  ]),
);

function modals(state = initialModalState, action: Action): ModalState {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        [action.modalID]: {
          ...state[action.modalID],
          isVisible: true,
        },
      };

    case 'HIDE_MODAL':
      return {
        ...state,
        [action.modalID]: {
          ...state[action.modalID],
          isVisible: false,
        },
      };

    case 'SET_MODAL_ERROR':
      return {
        ...state,
        [action.modalID]: {
          ...state[action.modalID],
          isVisible: true,
          error: action.error,
        },
      };

    case 'CLEAR_MODAL_ERROR':
      return {
        ...state,
        [action.modalID]: {
          ...state[action.modalID],
          error: null,
        },
      };

    default:
      return state;
  }
}

const rootReducer = combineReducers<RootState, Action>({
  // Raw Data state for single user
  dailyDataByDate,
  // OURA Teams state
  research, // `research` contains non-persisted OURA Teams data
  teams, // `teams` contains persisted OURA Teams data
  // UI state
  app,
  modals,
  trendsViewSettings,
});

export { rootReducer };
