import { composeWithDevTools } from '@redux-devtools/extension';
import { Component } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore, Middleware } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import { KEY_PREFIX } from 'redux-persist/constants';
import thunkMiddleware from 'redux-thunk';

import { CACHE_KEY } from './cache/constants';
import initSentry, { sentryMiddleware } from './common/sentry';
import { rootReducer, STATE_VERSION } from './reducers/reducers';
import { RootState } from './reducers/types';

const middlewares: Middleware[] = [thunkMiddleware];

if (process.env.ENABLE_SENTRY) {
  initSentry();
  middlewares.push(sentryMiddleware());
}

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(...middlewares),
    autoRehydrate(), // enhancer to sync store to LocalStorage
  ),
);

function clearStorage(adapter: Storage) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in adapter) {
    if (key.indexOf(KEY_PREFIX) === 0) {
      adapter.removeItem(key);
    }
  }
}

function clearTTLCache(adapter: Storage) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in adapter) {
    if (key.indexOf(KEY_PREFIX) === 0 && adapter[key].includes(CACHE_KEY)) {
      const reducer = JSON.parse(adapter[key]);
      reducer[CACHE_KEY] = 0;
      adapter.setItem(key, JSON.stringify(reducer));
    }
  }
}

const REDUCERS_TO_NOT_PERSIST: Array<keyof RootState> = [
  'research',
  'modals',
  // Persisting dailyDataByDate would be nice but the data is likely too big and
  // we run into localStorage quota limits.
  'dailyDataByDate',
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't refetch data in the background on each page render.
      // Only refetch after 10 minutes by default.
      staleTime: 10 * 60 * 1000,
      // Keep data in cache for up to 15 minutes.
      cacheTime: 15 * 60 * 1000,
      retry: (
        _failureCount,
        error: any, // Should be RemoteDataError
      ) => error.status < 400 || error.status >= 500, // Don't retry on 400 errors
    },
  },
});

interface State {
  rehydrated: boolean;
}

class AppProvider extends Component<{}, State> {
  public state = {
    rehydrated: false,
  };

  public componentDidMount() {
    // TODO implement with action

    // Some browsers seem to be missing localStorage and might throw when trying
    // to access the variable. Guard against this.
    try {
      const appData = localStorage.getItem(`${KEY_PREFIX}app`);
      const storedApp = appData && JSON.parse(appData);
      if (
        !storedApp ||
        !storedApp.stateVersion ||
        storedApp.stateVersion !== STATE_VERSION
      ) {
        // eslint-disable-next-line no-console
        console.log(
          'Purging store, state tree version mismatch:',
          storedApp?.stateVersion,
          STATE_VERSION,
        );

        clearStorage(localStorage);
        clearStorage(sessionStorage);
      } else {
        // set ttl for some reducers to 0 so their data gets refetched
        clearTTLCache(localStorage);
        clearTTLCache(sessionStorage);
      }
    } catch (error) {
      console.warn('Unable to check stored data version', error);
    }

    persistStore(
      store,
      {
        blacklist: REDUCERS_TO_NOT_PERSIST,
      },
      () => {
        this.setState({ rehydrated: true });
      },
    );
  }

  public render() {
    if (!this.state.rehydrated) {
      return null;
    }

    return (
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <BrowserRouter>{this.props.children}</BrowserRouter>
        </ReduxProvider>
      </QueryClientProvider>
    );
  }
}

export default AppProvider;
