function buildURL(baseURL: string, path: string, queryString?: string) {
  const url = baseURL + path;

  if (queryString) {
    return `${url}?${queryString}`;
  }

  return url;
}

export function getAPIUrl(path: string, queryString?: string) {
  const baseURL = '/api/';
  return buildURL(baseURL, path, queryString);
}

export function getAPIV2Url(path: string, queryString?: string) {
  const baseURL = '/api/v2/';
  return buildURL(baseURL, path, queryString);
}

export function getAccountAPIUrl(path: string, queryString?: string) {
  const baseURL = '/api/account/';
  return buildURL(baseURL, path, queryString);
}

export function getAccountAPIV2Url(path: string, queryString?: string) {
  const baseURL = '/api/v2/account/';
  return buildURL(baseURL, path, queryString);
}
