// This file is automatically generated. Do not edit! //
const colors = {
  blueDark10: 'rgb(221, 224, 229)',
  blueDark20: 'rgb(190, 198, 211)',
  blueDark30: 'rgb(175, 186, 204)',
  blueDark40: 'rgb(152, 167, 190)',
  blueDark50: 'rgb(126, 147, 178)',
  blueDark60: 'rgb(116, 138, 170)',
  blueDark70: 'rgb(92, 118, 158)',
  blueDark80: 'rgb(74, 103, 146)',
  blueDark90: 'rgb(54, 85, 130)',
  blueDark100: 'rgb(36, 71, 123)',
  blueDark120: 'rgb(18, 47, 89)',
  blueDark140: 'rgb(12, 35, 69)',
  blueDarkest10: 'rgb(215, 218, 223)',
  blueDarkest20: 'rgb(195, 199, 206)',
  blueDarkest30: 'rgb(160, 167, 178)',
  blueDarkest40: 'rgb(150, 157, 167)',
  blueDarkest50: 'rgb(122, 132, 148)',
  blueDarkest60: 'rgb(94, 105, 123)',
  blueDarkest70: 'rgb(75, 88, 108)',
  blueDarkest80: 'rgb(49, 67, 94)',
  blueDarkest90: 'rgb(27, 45, 73)',
  blueDarkest100: 'rgb(13, 31, 59)',
  blueLight10: 'rgb(216, 241, 255)',
  blueLight20: 'rgb(204, 236, 255)',
  blueLight30: 'rgb(194, 233, 255)',
  blueLight40: 'rgb(177, 226, 255)',
  blueLight50: 'rgb(172, 225, 255)',
  blueLight60: 'rgb(166, 223, 255)',
  blueLight70: 'rgb(150, 217, 255)',
  blueLight80: 'rgb(126, 208, 255)',
  blueLight90: 'rgb(123, 207, 255)',
  blueLight100: 'rgb(111, 203, 255)',
  blueMedium10: 'rgb(224, 232, 238)',
  blueMedium20: 'rgb(197, 215, 228)',
  blueMedium30: 'rgb(181, 206, 225)',
  blueMedium40: 'rgb(159, 197, 224)',
  blueMedium50: 'rgb(135, 181, 214)',
  blueMedium60: 'rgb(131, 179, 213)',
  blueMedium70: 'rgb(114, 164, 200)',
  blueMedium80: 'rgb(97, 157, 200)',
  blueMedium90: 'rgb(87, 153, 201)',
  blueMedium100: 'rgb(68, 141, 194)',
  brandBlue10: 'rgb(219, 222, 226)',
  brandBlue20: 'rgb(203, 209, 217)',
  brandBlue30: 'rgb(175, 183, 195)',
  brandBlue40: 'rgb(149, 161, 179)',
  brandBlue50: 'rgb(133, 148, 169)',
  brandBlue60: 'rgb(112, 130, 158)',
  brandBlue70: 'rgb(89, 112, 147)',
  brandBlue80: 'rgb(74, 98, 134)',
  brandBlue90: 'rgb(63, 88, 127)',
  brandBlue100: 'rgb(47, 74, 115)',
  brandCoral10: 'rgb(252, 237, 236)',
  brandCoral20: 'rgb(252, 221, 219)',
  brandCoral30: 'rgb(253, 210, 206)',
  brandCoral40: 'rgb(253, 195, 190)',
  brandCoral50: 'rgb(253, 170, 162)',
  brandCoral60: 'rgb(249, 159, 151)',
  brandCoral70: 'rgb(248, 142, 133)',
  brandCoral80: 'rgb(249, 128, 118)',
  brandCoral90: 'rgb(255, 118, 106)',
  brandCoral100: 'rgb(252, 101, 88)',
  brandEnsoBlue10: 'rgb(231, 238, 241)',
  brandEnsoBlue20: 'rgb(220, 233, 239)',
  brandEnsoBlue30: 'rgb(208, 229, 239)',
  brandEnsoBlue40: 'rgb(202, 228, 240)',
  brandEnsoBlue50: 'rgb(197, 228, 241)',
  brandEnsoBlue60: 'rgb(186, 223, 239)',
  brandEnsoBlue70: 'rgb(174, 218, 236)',
  brandEnsoBlue80: 'rgb(168, 215, 235)',
  brandEnsoBlue90: 'rgb(165, 211, 231)',
  brandEnsoBlue100: 'rgb(162, 211, 232)',
  brandLatte10: 'rgb(250, 249, 248)',
  brandLatte20: 'rgb(249, 248, 246)',
  brandLatte30: 'rgb(245, 242, 238)',
  brandLatte40: 'rgb(243, 239, 234)',
  brandLatte50: 'rgb(239, 234, 229)',
  brandLatte60: 'rgb(241, 234, 226)',
  brandLatte70: 'rgb(234, 228, 220)',
  brandLatte80: 'rgb(231, 225, 217)',
  brandLatte90: 'rgb(229, 221, 209)',
  brandLatte100: 'rgb(230, 222, 211)',
  greenDark10: 'rgb(232, 236, 226)',
  greenDark20: 'rgb(211, 219, 200)',
  greenDark30: 'rgb(198, 209, 183)',
  greenDark40: 'rgb(175, 194, 149)',
  greenDark50: 'rgb(154, 175, 125)',
  greenDark60: 'rgb(136, 161, 103)',
  greenDark70: 'rgb(116, 147, 75)',
  greenDark80: 'rgb(95, 129, 50)',
  greenDark90: 'rgb(81, 117, 31)',
  greenDark100: 'rgb(66, 107, 11)',
  greenLight10: 'rgb(242, 250, 231)',
  greenLight20: 'rgb(228, 247, 203)',
  greenLight30: 'rgb(210, 241, 170)',
  greenLight40: 'rgb(204, 241, 155)',
  greenLight50: 'rgb(191, 229, 140)',
  greenLight60: 'rgb(178, 228, 112)',
  greenLight70: 'rgb(161, 219, 84)',
  greenLight80: 'rgb(154, 221, 65)',
  greenLight90: 'rgb(139, 221, 32)',
  greenLight100: 'rgb(132, 215, 22)',
  monochrome10: 'rgb(249, 250, 251)',
  monochrome20: 'rgb(243, 244, 246)',
  monochrome30: 'rgb(229, 231, 235)',
  monochrome40: 'rgb(209, 213, 219)',
  monochrome50: 'rgb(156, 163, 175)',
  monochrome60: 'rgb(107, 114, 128)',
  monochrome70: 'rgb(75, 85, 99)',
  monochrome80: 'rgb(55, 65, 81)',
  monochrome90: 'rgb(31, 41, 55)',
  monochrome100: 'rgb(17, 24, 39)',
  orangeLight10: 'rgb(252, 246, 234)',
  orangeLight20: 'rgb(250, 235, 211)',
  orangeLight30: 'rgb(250, 229, 195)',
  orangeLight40: 'rgb(243, 214, 167)',
  orangeLight50: 'rgb(245, 212, 158)',
  orangeLight60: 'rgb(239, 197, 128)',
  orangeLight70: 'rgb(239, 189, 106)',
  orangeLight80: 'rgb(241, 184, 90)',
  orangeLight90: 'rgb(238, 176, 73)',
  orangeLight100: 'rgb(238, 166, 46)',
  temperatureChilling10: 'rgb(230, 255, 245)',
  temperatureChilling20: 'rgb(194, 255, 231)',
  temperatureChilling30: 'rgb(169, 255, 222)',
  temperatureChilling40: 'rgb(136, 251, 207)',
  temperatureChilling50: 'rgb(110, 250, 196)',
  temperatureChilling60: 'rgb(93, 247, 188)',
  temperatureChilling70: 'rgb(81, 244, 181)',
  temperatureChilling80: 'rgb(64, 241, 173)',
  temperatureChilling90: 'rgb(52, 238, 166)',
  temperatureChilling100: 'rgb(26, 239, 157)',
  temperatureFreezing10: 'rgb(230, 239, 251)',
  temperatureFreezing20: 'rgb(204, 220, 245)',
  temperatureFreezing30: 'rgb(175, 201, 240)',
  temperatureFreezing40: 'rgb(153, 184, 231)',
  temperatureFreezing50: 'rgb(119, 163, 228)',
  temperatureFreezing60: 'rgb(91, 142, 221)',
  temperatureFreezing70: 'rgb(78, 137, 226)',
  temperatureFreezing80: 'rgb(58, 119, 212)',
  temperatureFreezing90: 'rgb(39, 106, 208)',
  temperatureFreezing100: 'rgb(23, 94, 201)',
  temperatureHot10: 'rgb(255, 235, 235)',
  temperatureHot20: 'rgb(250, 217, 217)',
  temperatureHot30: 'rgb(247, 197, 197)',
  temperatureHot40: 'rgb(245, 176, 176)',
  temperatureHot50: 'rgb(245, 163, 163)',
  temperatureHot60: 'rgb(246, 148, 148)',
  temperatureHot70: 'rgb(247, 139, 139)',
  temperatureHot80: 'rgb(236, 119, 119)',
  temperatureHot90: 'rgb(241, 91, 91)',
  temperatureHot100: 'rgb(233, 74, 74)',
  temperatureQuiteCold10: 'rgb(231, 247, 251)',
  temperatureQuiteCold20: 'rgb(204, 237, 247)',
  temperatureQuiteCold30: 'rgb(181, 229, 245)',
  temperatureQuiteCold40: 'rgb(166, 225, 244)',
  temperatureQuiteCold50: 'rgb(147, 219, 242)',
  temperatureQuiteCold60: 'rgb(127, 213, 241)',
  temperatureQuiteCold70: 'rgb(121, 205, 232)',
  temperatureQuiteCold80: 'rgb(84, 194, 229)',
  temperatureQuiteCold90: 'rgb(79, 190, 226)',
  temperatureQuiteCold100: 'rgb(56, 185, 226)',
  temperatureQuiteHot10: 'rgb(255, 243, 229)',
  temperatureQuiteHot20: 'rgb(255, 234, 210)',
  temperatureQuiteHot30: 'rgb(255, 219, 177)',
  temperatureQuiteHot40: 'rgb(255, 212, 162)',
  temperatureQuiteHot50: 'rgb(255, 201, 140)',
  temperatureQuiteHot60: 'rgb(255, 192, 120)',
  temperatureQuiteHot70: 'rgb(250, 181, 101)',
  temperatureQuiteHot80: 'rgb(241, 165, 78)',
  temperatureQuiteHot90: 'rgb(251, 163, 60)',
  temperatureQuiteHot100: 'rgb(244, 149, 39)',
  temperatureRegular10: 'rgb(242, 255, 226)',
  temperatureRegular20: 'rgb(228, 248, 203)',
  temperatureRegular30: 'rgb(216, 248, 174)',
  temperatureRegular40: 'rgb(213, 247, 168)',
  temperatureRegular50: 'rgb(201, 242, 148)',
  temperatureRegular60: 'rgb(194, 244, 128)',
  temperatureRegular70: 'rgb(176, 238, 96)',
  temperatureRegular80: 'rgb(158, 240, 52)',
  temperatureRegular90: 'rgb(155, 238, 46)',
  temperatureRegular100: 'rgb(143, 232, 26)',
  temperatureWarm10: 'rgb(251, 252, 228)',
  temperatureWarm20: 'rgb(248, 249, 209)',
  temperatureWarm30: 'rgb(247, 249, 185)',
  temperatureWarm40: 'rgb(243, 246, 160)',
  temperatureWarm50: 'rgb(242, 246, 144)',
  temperatureWarm60: 'rgb(241, 246, 130)',
  temperatureWarm70: 'rgb(236, 241, 109)',
  temperatureWarm80: 'rgb(232, 238, 95)',
  temperatureWarm90: 'rgb(232, 239, 74)',
  temperatureWarm100: 'rgb(231, 239, 54)',
} as const;

export default colors;

export type ColorOura = keyof typeof colors;
