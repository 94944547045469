/* eslint-disable @typescript-eslint/default-param-last */
import { Action } from '../actions/types';
import { TeamsState } from './types';

const initialTeamsState: TeamsState = {};

export default function teamsReducer(
  state = initialTeamsState,
  action: Action,
): TeamsState {
  switch (action.type) {
    case 'SELECT_ORG':
      return {
        ...state,
        selectedOrgUid: action.id,
      };

    case 'SET_GROUP_ANALYSIS_METRICS':
      return {
        ...state,
        groupAnalysisMetrics: action.metrics,
      };

    default:
      return state;
  }
}
