import { useEffect, useState } from 'react';

export default function useWindowWidth() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    function storePageWidth() {
      setPageWidth(window.innerWidth);
    }
    window.addEventListener('resize', storePageWidth, false);
    return () => {
      window.removeEventListener('resize', storePageWidth);
    };
  }, []);

  return pageWidth;
}
