import {
  addDays,
  areIntervalsOverlapping,
  max,
  min,
  parseISO,
  subDays,
} from 'date-fns';

import { ReceiveDataAction, RequestDataAction } from '../actions/sharedTypes';
import { CACHE_KEY } from '../cache/constants';
import { toDateString } from '../common/dates';
import { DataFetchedByDateState } from './types';

export function handleRequestData<T, A extends Omit<RequestDataAction, 'type'>>(
  state: DataFetchedByDateState<T>,
  action: A,
): DataFetchedByDateState<T> {
  // Fetch all data. Replace whatever was in the state.
  if (action.range.type === 'all') {
    return {
      ...state,
      fetchStatus: {
        type: 'all',
        isFetching: true,
        isLoaded:
          state.fetchStatus.type === 'all' ? state.fetchStatus.isLoaded : false,
      },
    };
  }

  // ...otherwise we're fetching a range

  if (state.fetchStatus.type === 'range') {
    const minRequestedDate = parseISO(state.fetchStatus.minDateRequested);
    const maxRequestedDate = parseISO(state.fetchStatus.maxDateRequested);

    // We need to add one day to each end because we only fetch data for
    // days that we don't already have, meaning the intervals are always
    // adjacent to each other.
    const existingRange = {
      start: subDays(minRequestedDate, 1),
      end: addDays(maxRequestedDate, 1),
    };

    // If we're fetching a range and there's overlap between the requested range
    // and what we already have in the state, extend the stored range in the state.
    if (
      areIntervalsOverlapping(action.range, existingRange, { inclusive: true })
    ) {
      return {
        ...state,
        fetchStatus: {
          type: 'range',
          minDateRequested: toDateString(
            min([action.range.start, minRequestedDate]),
          ),
          maxDateRequested: toDateString(
            max([action.range.end, maxRequestedDate]),
          ),
        },
      };
    }
  }

  // If we're fetching a range that doesn't overlap with the previously
  // requested date range, simply store the action's dates. It means we may
  // re-fetch that we may already have in the store but that's fine.

  return {
    ...state,
    fetchStatus: {
      type: 'range',
      minDateRequested: toDateString(action.range.start),
      maxDateRequested: toDateString(action.range.end),
    },
  };
}

export function handleReceiveData<
  TPayload,
  TData,
  A extends ReceiveDataAction<TPayload>,
>(
  state: DataFetchedByDateState<TData>,
  action: A,
  transformData: (payload: TPayload) => TData,
): DataFetchedByDateState<TData> {
  return {
    ...state,
    [CACHE_KEY]: Date.now(),
    data: {
      ...state.data,
      ...transformData(action.payload),
    },
    fetchStatus:
      action.range.type === 'all'
        ? {
            type: 'all',
            isFetching: false,
            isLoaded: true,
          }
        : state.fetchStatus,
  };
}
