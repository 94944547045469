import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { logException } from '../common/sentry';
import LoadingAnimation, { Box } from '../components/LoadingAnimation';
import {
  getMessagesForLocale,
  LOCALE_DEFAULT,
  LocaleMessagesJson,
  LOCALES_SUPPORTED,
  LocaleSupported,
  MessageKey,
} from './setup';

export function isSupportedLocale(locale: string): locale is LocaleSupported {
  return LOCALES_SUPPORTED.includes(locale as any);
}

export type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface LocaleProps {
  changeLocale: (key: LocaleSupported) => void;
  selectedLocale: LocaleSupported;
  firstDayOfWeek: FirstDayOfWeek;
}

const Locale = createContext<LocaleProps | undefined>(undefined);

function useLocaleValues() {
  const { supportedLanguages: supportedLanguagesString } = useFlags();
  const supportedLanguages: Set<string> = useMemo(
    () =>
      new Set(
        (supportedLanguagesString ?? LOCALE_DEFAULT)
          .split(',')
          .map((s: string) => s.trim()),
      ),
    [supportedLanguagesString],
  );
  const [locale, setLocale] = useState(LOCALE_DEFAULT);
  const [messages, setMessages] = useState<LocaleMessagesJson>();

  const isUserRoute = window.location.pathname.startsWith('/user');

  useEffect(() => {
    async function changeLocaleLoaded() {
      // The feature flag does not apply to /user routes
      if (isUserRoute || supportedLanguages.has(locale)) {
        const messagesForLocale = await getMessagesForLocale(locale);
        setMessages(messagesForLocale);
      } else {
        const messagesForLocale = await getMessagesForLocale(LOCALE_DEFAULT);
        setMessages(messagesForLocale);
      }
    }
    changeLocaleLoaded();
  }, [locale, supportedLanguages, isUserRoute]);

  useEffect(() => {
    try {
      const localeFromStorage = localStorage.getItem('locale');
      if (localeFromStorage && isSupportedLocale(localeFromStorage)) {
        setLocale(localeFromStorage);
      }
    } catch (error) {
      console.warn('Unable to get locale from local storage', error);
    }
  }, []);

  const setSelectedLocale = (selectedLocale: LocaleSupported) => {
    try {
      localStorage.setItem('locale', selectedLocale);
      setLocale(selectedLocale);
    } catch (error) {
      console.warn('Unable to set locale to local storage', error);
    }
  };

  let timeZone;
  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    timeZone = undefined;
  }

  // NB: This is not very sophisticated and results in wrong values in many countries
  const firstDayOfWeek: FirstDayOfWeek =
    timeZone?.startsWith('America') || locale === 'ja' ? 0 : 1;

  return {
    selectedLocale: locale,
    changeLocale: setSelectedLocale,
    messages,
    timeZone,
    firstDayOfWeek,
  };
}

export function LocaleProvider({ children }: { children: ReactNode }) {
  const { messages, selectedLocale, changeLocale, timeZone, firstDayOfWeek } =
    useLocaleValues();

  const contextValue = useMemo(
    () => ({
      selectedLocale,
      changeLocale,
      firstDayOfWeek,
    }),
    [changeLocale, selectedLocale, firstDayOfWeek],
  );

  if (!messages) {
    return (
      <Box>
        <div className="row">
          <div className="col-xs-12">
            <LoadingAnimation />
          </div>
        </div>
      </Box>
    );
  }

  return (
    <Locale.Provider value={contextValue}>
      <IntlProvider
        onError={(err) => {
          if (
            err.code === 'MISSING_TRANSLATION' ||
            err.code === 'MISSING_DATA'
          ) {
            console.error('Error:', err.message);
            logException(err);
            return;
          }
          throw err;
        }}
        messages={messages}
        locale={selectedLocale}
        defaultLocale={LOCALE_DEFAULT}
        timeZone={timeZone}
      >
        {children}
      </IntlProvider>
    </Locale.Provider>
  );
}

export function useLocale() {
  const context = useContext(Locale);
  if (!context) {
    throw new Error(
      'useLocale must be used within a LocaleProvider. ' +
        'Consider moving LocaleProvider higher up in the component tree',
    );
  }

  return context;
}

/**
 * This is built over React-Intl's <FormattedMessage /> for convenience
 * @example
 * t("guide_infotext_sleep_score", { score })
 */
export function t(id: MessageKey, values?: Record<string, ReactNode>) {
  return <FormattedMessage id={id} values={values} />;
}
