import styled from '@emotion/styled';

import { t } from '../localization/LocaleContext';
import LoadingAnimation, { Box } from './LoadingAnimation';

const Text = styled.h3`
  ${({ theme }) => theme.textStyles.labelL};
  color: ${({ theme }) => theme.colors.blueDark100};
  // Initially, 1rem is smaller because of some vendor CSS
  font-size: 16px;
`;

export default function LoadingScreen() {
  return (
    // Note: these spinner styles are also used in LocaleProvider component
    <Box>
      <div className="row">
        <div className="col-xs-12">
          <LoadingAnimation />
          <Text>{t('general_loading')}</Text>
        </div>
      </div>
    </Box>
  );
}
