import {
  asyncWithLDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

import { useAnalyticsID, useUserEmail } from '../remoteData/account';

export async function getFeatureFlagProvider() {
  return asyncWithLDProvider({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clientSideID: process.env.LAUNCH_DARKLY_CLIENTSIDE_ID!,
    options: {
      streaming: false,
      // Don't show or store any user attributes in LaunchDarkly. Properties
      // (such as email address) may be sent to the server in order to allow
      // for property-based user segmentation, but this data is not stored on
      // the servers.
      allAttributesPrivate: true,
      // Don't send tracking events. Flag evaluations and users will not show up
      // in the LaunchDarkly admin interface.
      sendEvents: false,
      // Don't get goals, which are needed for click/pageview events and A/B tests.
      fetchGoals: false,
    },
  });
}

export function FeatureFlagSettings() {
  const uid = useAnalyticsID();
  const email = useUserEmail();
  const ldClient = useLDClient();

  useEffect(() => {
    if (uid && ldClient) {
      // Email should not be sent to LD since we have allAttributesPrivate enabled
      ldClient.identify({ key: uid, email });
    }
    // Disabled just for ldClient
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, email]);

  return null;
}

export { useFlags } from 'launchdarkly-react-client-sdk';
