/* eslint-disable @typescript-eslint/default-param-last */
import { addMinutes } from 'date-fns';

import { Action } from '../actions/types';
import { toGroupMembersDailyMetrics } from '../api/converters/research';
import { toDateString } from '../common/dates';
import { GroupMembersDailyMetrics } from '../common/researchTypes';
import {
  handleReceiveDailyData,
  handleRequestDailyData,
  initialDailyDataByDateState,
} from './dailyData';
import {
  handleReceiveGroupNotes,
  handleRequestGroupNotes,
} from './groupNotesReducers';
import { ResearchState } from './types';

const initialResearchState: ResearchState = {
  data: {
    participantDailyData: {},
    membersDailyMetrics: {},
    groupNotes: {},
  },
};

export default function research(
  state = initialResearchState,
  action: Action,
): ResearchState {
  switch (action.type) {
    case 'RECEIVE_GROUP_MEMBERS_DAILY_METRICS': {
      const { locale, groupID, data, metrics, endDate, startDate } = action;
      const existingDailyData = state.data.membersDailyMetrics[groupID];

      // Cache data for 10 minutes
      const expiresAt = addMinutes(new Date(), 10);
      const endDateString = toDateString(endDate);
      const updatedGroupData: GroupMembersDailyMetrics = {
        dailyData: {
          ...existingDailyData?.dailyData,
          ...toGroupMembersDailyMetrics(
            locale,
            data,
            metrics,
            startDate,
            endDate,
            existingDailyData?.dailyData,
          ),
        },
        expiresAt: {
          ...existingDailyData?.expiresAt,
          [endDateString]: metrics.reduce<{
            [metricID: string]: Date | undefined;
          }>(
            (result, key) => {
              // eslint-disable-next-line no-param-reassign
              result[key] = expiresAt;
              return result;
            },
            { ...existingDailyData?.expiresAt?.[endDateString] },
          ),
        },
      };
      return {
        ...state,
        data: {
          ...state.data,
          membersDailyMetrics: {
            ...state.data.membersDailyMetrics,
            [groupID]: updatedGroupData,
          },
        },
      };
    }

    case 'REQUEST_PARTICIPANT_DAILY_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          participantDailyData: {
            // TODO: Should we clear daily summaries from other users so that we
            // don't balloon memory usage?
            ...state.data.participantDailyData,
            [action.participantID]: handleRequestDailyData(
              state.data.participantDailyData[action.participantID] ||
                initialDailyDataByDateState,
              action,
            ),
          },
        },
      };

    case 'RECEIVE_PARTICIPANT_DAILY_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          participantDailyData: {
            ...state.data.participantDailyData,
            [action.participantID]: handleReceiveDailyData(
              state.data.participantDailyData[action.participantID] ||
                initialDailyDataByDateState,
              action,
            ),
          },
        },
      };

    case 'REQUEST_GROUP_NOTES': {
      return {
        ...state,
        data: {
          ...state.data,
          groupNotes: handleRequestGroupNotes(state.data.groupNotes, action),
        },
      };
    }
    case 'RECEIVE_GROUP_NOTES':
      return {
        ...state,
        data: {
          ...state.data,
          groupNotes: handleReceiveGroupNotes(state.data.groupNotes, action),
        },
      };

    case 'GROUP_NOTES_ERROR':
      return {
        ...state,
        data: {
          ...state.data,
          groupNotes: {
            [action.groupId]: {
              data: undefined,
              error: action.error,
              isLoading: false,
              fetchStatus: { type: 'not-fetched' },
            },
          },
        },
      };

    default:
      return state;
  }
}
