import { cloneDeep, merge } from 'lodash';

import {
  ReceiveGroupNotesAction,
  RequestGroupNotesAction,
} from '../actions/group/types';
import { toGroupNotes } from '../api/converters/research';
import { ApiGetGroupNotesResponse } from '../api/types';
import { GroupNotes } from '../common/researchTypes';
import {
  handleReceiveData,
  handleRequestData,
} from './dataByRangeReducerHelpers';
import { DataFetchedByDateState, GroupNotesState } from './types';

const defaultGroupNotesStateForGroup = {
  data: undefined,
  fetchStatus: {
    type: 'not-fetched',
  },
} as const;

function getGroupStateOrDefault<
  A extends Omit<RequestGroupNotesAction, 'type'>,
>(
  state: GroupNotesState,
  action: A,
): DataFetchedByDateState<GroupNotes | undefined> {
  return state[action.groupId] ?? defaultGroupNotesStateForGroup;
}

export function handleRequestGroupNotes<
  A extends Omit<RequestGroupNotesAction, 'type'>,
>(state: GroupNotesState, action: A): GroupNotesState {
  return {
    ...state,
    [action.groupId]: {
      ...handleRequestData(getGroupStateOrDefault<A>(state, action), action),
      isLoading: true,
    },
  };
}

function mergeGroupNotes(prev: GroupNotes | undefined, toAdd: GroupNotes) {
  return merge({}, cloneDeep(prev), toAdd);
}

function getUpdateGroupNotesFunction(previous: GroupNotes | undefined) {
  return (groupNotes: ApiGetGroupNotesResponse) =>
    mergeGroupNotes(previous, toGroupNotes(groupNotes));
}

export function handleReceiveGroupNotes<
  A extends Omit<ReceiveGroupNotesAction, 'type'>,
>(state: GroupNotesState, action: A): GroupNotesState {
  return {
    ...state,
    [action.groupId]: {
      ...handleReceiveData(
        getGroupStateOrDefault<A>(state, action),
        action,
        getUpdateGroupNotesFunction(state[action.groupId]?.data),
      ),
      isLoading: false,
    },
  };
}
