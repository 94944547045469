import { MetricSet } from '../common/researchTypes';
import { TrendsQuery } from '../common/types';

export const defaultTrendsConfigurationName = 'Default';

export const defaultTrendsQuery: TrendsQuery = {
  subqueries: [
    {
      key: 'sleep.total_sleep_duration',
      y1: 'sleep.total_sleep_duration',
    },
  ],
  timeAggregation: 'daily',
  setLocally: true,
};

export const defaultMetricSet: Omit<MetricSet, 'id'> = {
  name: 'Scores',
  metrics: [
    'daily_readiness.score',
    'daily_sleep.score',
    'daily_activity.score',
  ],
};
