import styled from '@emotion/styled';

import colors from '../common/design-tokens/colors';

const LOOP_LENGTH = '3s';
// Have the animation start at the middle, i.e. when the logo is full
const LOOP_DELAY = '-1.5s';
const MACRON_WIDTH = 19.2;

export const Box = styled.section`
  margin-top: 2em;
  text-align: center;
`;

const Svg = styled.svg`
  .macron {
    transform-origin: center center;
    stroke-dasharray: ${MACRON_WIDTH};
    stroke-dashoffset: ${MACRON_WIDTH};
    animation:
      macron-dash ${LOOP_LENGTH} cubic-bezier(1, 0, 0.39, 1) ${LOOP_DELAY}
        infinite,
      macron-shift ${LOOP_LENGTH} cubic-bezier(1, 0, 0.39, 1) ${LOOP_DELAY}
        infinite;
  }
  // We can't animate transform in two different ways for one element
  .macronContainer {
    transform-origin: center 0;
    animation: flip-x ${LOOP_LENGTH} steps(1, end) ${LOOP_DELAY} infinite;
  }

  @keyframes flip-x {
    0% {
      transform: scale(1, 1);
    }
    // Since we have just one step, we need the "end" to be in the middle of the animation
    50% {
      transform: scale(-1, 1);
    }
  }

  @keyframes macron-dash {
    0% {
      stroke-dashoffset: ${MACRON_WIDTH};
    }
    20% {
      stroke-dashoffset: ${MACRON_WIDTH};
    }
    30% {
      stroke-dashoffset: 0;
    }
    60% {
      stroke-dashoffset: 0;
    }
    85% {
      stroke-dashoffset: ${MACRON_WIDTH};
    }
    100% {
      stroke-dashoffset: ${MACRON_WIDTH};
    }
  }

  @keyframes macron-shift {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(0px);
    }
    65% {
      transform: translateX(0px);
    }
    // Since the x-axis flips over, we translate "the same way back"
    100% {
      transform: translateX(-3px);
    }
  }

  .ring {
    transform-origin: center center;
    transform: translate(0, 6px) rotate(-90deg);
  }

  .ringSegment {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation:
      ring-dash ${LOOP_LENGTH} cubic-bezier(1, 0, 0.39, 1) ${LOOP_DELAY}
        infinite,
      flip-y ${LOOP_LENGTH} steps(1, end) ${LOOP_DELAY} infinite;
    transform-origin: center center;
  }

  @keyframes flip-y {
    0% {
      transform: scale(1, 1);
    }
    // Since we have just one step, we need the "end" to be in the middle of the animation
    50% {
      transform: scale(1, -1);
    }
  }

  @keyframes ring-dash {
    0% {
      stroke-dashoffset: 100;
    }
    20% {
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dashoffset: 0;
    }
    80% {
      stroke-dashoffset: 100;
    }
    100% {
      stroke-dashoffset: 100;
    }
  }
`;

const CANVAS_SIZE = 40;
// Use an easy number for the circumference
const C = 100;
// c = 2πr => r = c/2π
const R = C / (2 * Math.PI);

const MACRON_OFFSET_X = (CANVAS_SIZE - MACRON_WIDTH) / 2;
export default function LoadingAnimation() {
  return (
    <div style={{ width: 110, margin: 'auto' }}>
      <Svg
        width={110}
        height={135}
        viewBox={`0 0 ${CANVAS_SIZE} ${CANVAS_SIZE}`}
      >
        <g className="ring">
          <circle
            className="ringSegment"
            cx={CANVAS_SIZE / 2}
            cy={CANVAS_SIZE / 2}
            r={R}
            fill="transparent"
            stroke={colors.blueDark100}
            strokeWidth={4}
          />
        </g>
        <g className="macronContainer">
          <g className="macron">
            <line
              x1={MACRON_OFFSET_X}
              x2={MACRON_OFFSET_X + MACRON_WIDTH}
              y1="2"
              y2="2"
              stroke={colors.blueDark100}
              strokeWidth={4}
            />
          </g>
        </g>
      </Svg>
    </div>
  );
}
