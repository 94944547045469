import { groupBy } from 'lodash';

import { assertNever } from '../../common/utils';
import { GetApiUserNotesResponse, UserNoteStatus } from '../types';

// eslint-disable-next-line import/prefer-default-export
export function toUserNotes(response: GetApiUserNotesResponse) {
  switch (response.status) {
    case UserNoteStatus.NotAvailable:
      return response;
    case UserNoteStatus.Available:
      return {
        status: response.status,
        notes: groupBy(response.notes, 'day'),
      };

    default:
      return assertNever(response);
  }
}
