// This file is automatically generated. Do not edit! //
const spacers = {
  l: '2rem',
  l2: '2.5rem',
  m: '1.5rem',
  s: '1rem',
  xl: '3rem',
  xs: '0.5rem',
  xxl: '4rem',
  xxs: '0.25rem',
} as const;

export default spacers;
