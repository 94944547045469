import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import { addMilliseconds } from 'date-fns';

import { getToday } from './dates';
import { Measurement, Metric, MetricKey, metrics } from './metrics';
import { LocaleData } from './types';

// Normalization

/**
 * Represents a value that has been run through `normalizeValue`
 * and is now in the default units for that particular measurement.
 */
interface NormalizedValue {
  measurement: Measurement;
  value: number;
}

/**
 * Converts raw measurement values into the normalized unit. For example all durations
 * are normalized into seconds. This allows us to only write the unit conversions
 * "from seconds" and thus avoids the need for multitude of conversion directions
 * (minutes to hours, minutes to seconds, hours to minutes, etc).
 */
function normalizeValue(metric: Metric, raw: number): NormalizedValue {
  switch (metric.data_unit) {
    case 'hour':
      return { measurement: metric.measurement, value: raw * 60 * 60 };

    case 'half_hour':
      return { measurement: metric.measurement, value: raw * 30 * 60 };

    case 'minute':
      return { measurement: metric.measurement, value: raw * 60 };

    default:
      return { measurement: metric.measurement, value: raw };
  }
}

// Data format

const unitSymbols = {
  millisecond: 'ms',
  second: 's',
  minute: 'min',
  hour: 'h',

  meter: 'm',
  kilometer: 'km',
  mile: 'miles',

  kcal: 'kcal',
  large_calorie: 'Cal',

  percentage: '%',
  score: '',

  count: 'times',
  warning: 'alerts',

  beats_per_minute: 'bpm',
  breaths_per_minute: '/min',

  celsius: '°C',
  fahrenheit: '°F',

  met: 'MET',
} as const;

type MeasurementPart =
  | { type: 'value'; value: string | number }
  | { type: 'unit'; value: string }
  | { type: 'separator'; value: string };

function valuePart(value: string | number): MeasurementPart {
  return { type: 'value', value };
}
function unitPart(value: string): MeasurementPart {
  return { type: 'unit', value };
}
function separatorPart(): MeasurementPart {
  return { type: 'separator', value: ' ' };
}

type MeasurementDataFormat = MeasurementPart[];

function renderDataFormat(dataFormat: MeasurementDataFormat) {
  return dataFormat.map(({ value }) => value).join('');
}

function buildHourMinuteDataFormat(
  accuracy: 'second' | 'minute',
  rawSecs: number,
): MeasurementDataFormat {
  const abs = Math.abs(rawSecs);
  const hours = Math.floor(abs / 3600);
  const mins = (accuracy === 'minute' ? Math.round : Math.floor)(
    (abs % 3600) / 60,
  );
  const seconds = Math.round(abs % 60);

  if (accuracy === 'minute' || abs >= 60) {
    const hourPart =
      hours !== 0
        ? [valuePart(hours), unitPart(unitSymbols.hour), separatorPart()]
        : [];
    return [...hourPart, valuePart(mins), unitPart(unitSymbols.minute)];
  }

  return [valuePart(seconds), unitPart(unitSymbols.second)];
}

function buildMeasurementDataFormatWithOptions(
  locale: LocaleData,
  key: MetricKey,
  raw: number,
  options: {
    convertFromChartUnits?: boolean;
    timeOfDayInHourMinute?: boolean;
  },
): MeasurementDataFormat {
  const metric: Metric = metrics[key];
  const convertedRaw = options.convertFromChartUnits
    ? fromChartUnitValue(locale, metric, raw)
    : raw;
  const { value, hasSpace, unitSymbol } = toTargetUnits(
    locale,
    metric,
    'display_unit',
    convertedRaw,
  );
  const formatter =
    metric.measurement === 'time_of_day'
      ? formatTimeOfDay(metric)
      : metric.measurement === 'count'
        ? formatCount
        : format(metric.display_format || 'd');

  if (
    metric.display_unit === 'hour_minute' ||
    (metric.measurement === 'time_of_day' && options.timeOfDayInHourMinute)
  ) {
    return buildHourMinuteDataFormat(accuracyFromSpec(metric), value);
  }
  return [
    valuePart(formatter(value)),
    hasSpace ? separatorPart() : undefined,
    unitPart(unitSymbol),
  ].filter((part): part is MeasurementPart => !!part);
}

export function buildMeasurementDataFormat(
  locale: LocaleData,
  key: MetricKey,
  raw: number,
): MeasurementDataFormat {
  return buildMeasurementDataFormatWithOptions(locale, key, raw, {});
}

// Conversions

function convertTo(unit: string, { value }: NormalizedValue) {
  switch (unit) {
    case 'mile':
      return value * 0.000621371;

    // NOTE: This will not work for absolute temperatures, only for deltas!
    case 'fahrenheit':
      return value * (9 / 5);

    case 'kilometer':
      return value / 1000;

    case 'minute':
      return value / 60;

    case 'hour':
    case 'time_of_day_delta_hours':
      return value / 3600;

    default:
      return value;
  }
}

const hasSpaceBetweenValueAndSymbol: { [unit: string]: boolean | undefined } = {
  mile: true,
  breaths_per_minute: true,
  count: true,
  warning: true,
  large_calorie: true,
};

function toTargetUnits(
  locale: LocaleData,
  metric: Metric,
  targetUnits: 'display_unit' | 'chart_unit',
  raw: number,
) {
  // Use the requested format, or fall back to display format
  const unitSpecs = metric[targetUnits] || metric.display_unit;
  const unit =
    typeof unitSpecs === 'object' ? unitSpecs[locale.units] : unitSpecs;

  return {
    value: convertTo(unit, normalizeValue(metric, raw)),
    hasSpace: hasSpaceBetweenValueAndSymbol[unit],
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    unitSymbol: (unitSymbols as Record<string, string>)[unit],
  };
}

function fromChartUnitValue(
  locale: LocaleData,
  metric: Metric,
  chartValue: number,
) {
  // NOTE: This will not work for absolute temperatures, only for deltas!
  const ratio = 1 / toTargetUnits(locale, metric, 'chart_unit', 1).value;
  return chartValue * ratio;
}

export function getChartUnitsConverter(locale: LocaleData, key: MetricKey) {
  return function convertToChartUnits(raw: number): number {
    const metric: Metric = metrics[key];
    return toTargetUnits(locale, metric, 'chart_unit', raw).value;
  };
}

// Rendering

function accuracyFromSpec(metric: Metric) {
  return metric.data_unit.includes('second') ? 'second' : 'minute';
}

function formatTimeOfDay(metric: Metric) {
  return (deltaSecs: number) =>
    timeFormat(metric.display_format || '%H:%M')(
      addMilliseconds(getToday(), deltaSecs * 1000),
    );
}

function formatCount(count: number) {
  return count % 1 === 0 ? format('d')(count) : format('.1f')(count);
}

export function renderMeasurementDifference(
  locale: LocaleData,
  key: MetricKey,
  raw: number,
  options?: { convertFromChartUnits: boolean },
) {
  const sign = raw < 0 ? '−' : '+';
  const text = renderMeasurementWithOptions(locale, key, Math.abs(raw), {
    ...options,
    timeOfDayInHourMinute: true,
  });
  return `${sign}${text.replace(/^[+-]/, '')}`;
}

export function renderMeasurementDifferenceFromChartUnits(
  locale: LocaleData,
  key: MetricKey,
  chartValue: number,
) {
  return renderMeasurementDifference(locale, key, chartValue, {
    convertFromChartUnits: true,
  });
}

export function shouldHideTrendPercentage(key: MetricKey) {
  const { measurement } = metrics[key];
  return (
    measurement === 'time_of_day' ||
    key === 'daily_readiness.temperature_deviation' ||
    key === 'daily_readiness.temperature_trend_deviation'
  );
}

export function renderFromChartUnits(
  locale: LocaleData,
  key: MetricKey,
  chartValue: number,
) {
  return renderMeasurementWithOptions(locale, key, chartValue, {
    convertFromChartUnits: true,
  });
}

export function renderMeasurement(
  locale: LocaleData,
  key: MetricKey,
  raw: number,
) {
  return renderMeasurementWithOptions(locale, key, raw, {});
}

function renderMeasurementWithOptions(
  locale: LocaleData,
  key: MetricKey,
  raw: number,
  options: {
    convertFromChartUnits?: boolean;
    timeOfDayInHourMinute?: boolean;
  },
) {
  return renderDataFormat(
    buildMeasurementDataFormatWithOptions(locale, key, raw, options),
  );
}
