import * as Sentry from '@sentry/browser';
import { mapValues } from 'lodash';
import { useEffect } from 'react';
import { Dispatch, Middleware } from 'redux';

import { Action } from '../actions/types';
import { RootState } from '../reducers/types';

export default function initSentry() {
  const { SENTRY_DSN, RELEASE_VERSION } = process.env;

  const uriComponents = window.location.hostname.split('.');
  // Results in ouraring, ourastage, ourasandbox
  const environment =
    uriComponents.length > 1 ? uriComponents[1] : uriComponents[0];

  Sentry.init({
    dsn: SENTRY_DSN,
    release: RELEASE_VERSION,
    environment,
    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.category === 'console' &&
        breadcrumb.level === Sentry.Severity.Log
      ) {
        // Hide asserts and console.logs. Asserts get Severity.Log level:
        // https://github.com/getsentry/sentry-javascript/blob/4bfbf99daea758ec18db11da7d96590dc7d6f1b5/packages/types/src/severity.ts#L27
        return null;
      }
      return breadcrumb;
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logException(ex: Error, context?: any) {
  Sentry.withScope((_scope) => {
    if (context) {
      Sentry.setExtras(context);
    }
    Sentry.captureException(ex);
  });

  if (window.console && console.error) {
    console.error(ex);
  }
}

const HIDE_CONTENT = 'HIDDEN';

interface SentryUserContextProps {
  userUID?: string;
}

/**
 * A React component that sets the user Sentry context
 */
export function SentryUserContext({ userUID }: SentryUserContextProps) {
  useEffect(() => {
    if (userUID && process.env.ENABLE_SENTRY) {
      Sentry.setUser({
        id: userUID,
      });
    }
  }, [userUID]);

  return null;
}

// Based on https://github.com/captbaritone/raven-for-redux/issues/93#issuecomment-435854873
export function sentryMiddleware(): Middleware<
  {},
  RootState,
  Dispatch<Action>
> {
  return (store) => {
    Sentry.addGlobalEventProcessor((event) => {
      const state = store.getState();

      return {
        ...event,
        extra: {
          ...event.extra,
          'redux:state': cleanState(state),
        },
      };
    });

    return (next) => (action) => {
      const { type, ...data } = cleanAction(action);
      Sentry.addBreadcrumb({
        category: 'redux-action',
        message: type,
        data,
      });

      return next(action);
    };
  };
}

const ACTIONS_TO_HIDE_DATA_FROM = ['RECEIVE_', 'persist/'];

function cleanAction(action: Action) {
  if (
    ACTIONS_TO_HIDE_DATA_FROM.some((type) => action.type.indexOf(type) === 0)
  ) {
    return {
      type: action.type,
      data: HIDE_CONTENT,
    };
  }

  return action;
}

const REDUCERS_TO_HIDE = new Set<keyof RootState>([
  'research',
  'teams',
  'dailyDataByDate',
]);

function cleanState(state: RootState) {
  return mapValues(state, (value, key) =>
    REDUCERS_TO_HIDE.has(key as keyof RootState) ? HIDE_CONTENT : value,
  );
}
