// This file is automatically generated. Do not edit! //
const textStyles = {
  labelL: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.25,
    fontSize: '1rem',
    fontWeight: 400,
  },
  labelLEmphasized: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.25,
    fontSize: '1rem',
    fontWeight: 700,
  },
  labelM: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.25,
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  labelS: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.25,
    fontSize: '0.6875rem',
    fontWeight: 700,
  },
  labelXl: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.2,
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  labelXs: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.2,
    fontSize: '0.625rem',
    fontWeight: 700,
  },
  paragraphM: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.5,
    fontSize: '1rem',
    fontWeight: 400,
  },
  paragraphS: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.4,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  scoreL: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1,
    fontSize: '4rem',
    fontWeight: 400,
  },
  scoreM: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1,
    fontSize: '3rem',
    fontWeight: 400,
  },
  scoreS: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.18,
    fontSize: '2rem',
    fontWeight: 400,
  },
  titleL: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.2,
    fontSize: '1.75rem',
    fontWeight: 400,
  },
  titleM: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.1,
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  titleS: {
    fontFamily: '"AkkuratLL", "Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: 1.35,
    fontSize: '1.125rem',
    fontWeight: 700,
  },
} as const;

export default textStyles;
