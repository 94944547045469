import { extent as d3Extent, mean as d3Mean } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { addMinutes } from 'date-fns';
import { useCallback } from 'react';
import { FormatNumberOptions, IntlShape, useIntl } from 'react-intl';

import { MessageKey } from '../localization/setup';
import { MetricKey } from './metrics';
import { LocaleData } from './types';

export type MetricKeyExtended = MetricKey | 'daytime_hr' | 'generic_count';

export type NumericMeasurementType =
  | 'alert'
  | 'breathRate'
  | 'count'
  | 'distance'
  | 'energy'
  | 'heartRateVariability'
  | 'heartRate'
  | 'met'
  | 'ratio'
  | 'score'
  | 'step'
  | 'temperatureDelta';

export type DateMeasurementType = 'timeOfDay' | 'duration';

export type MeasurementType = NumericMeasurementType | DateMeasurementType;

type NumericMeasurementValue = {
  type: NumericMeasurementType;
  value: number;
  unit?: FormatNumberOptions['unit'];
};
type DateMeasurementValue = { type: DateMeasurementType; value: Date };
type UnsupportedValue = {
  type: 'unsupported';
  value: number;
  metric: MetricKeyExtended;
};

export type MeasurementValue =
  | NumericMeasurementValue
  | DateMeasurementValue
  | UnsupportedValue;

export interface Datum {
  date: Date;
  value?: MeasurementValue;
  dataLoaded?: boolean;
}

export function isDateMeasurementValue(m: MeasurementValue) {
  return m.value instanceof Date;
}

export function toMeasurementValue(
  locale: LocaleData,
  metric: MetricKeyExtended,
  value: number,
): MeasurementValue {
  switch (metric) {
    case 'daily_activity.score':
    case 'daily_readiness.score':
    case 'daily_sleep.score':
    case 'sleep.score':
      return { type: 'score', value };

    case 'daily_activity.steps':
      return { type: 'step', value };

    case 'generic_count':
    case 'sleep.restless_periods':
      return { type: 'count', value };

    case 'daily_activity.high_activity_time':
    case 'daily_activity.low_activity_time':
    case 'daily_activity.medium_activity_time':
    case 'daily_activity.non_wear_time':
    case 'daily_activity.resting_time':
    case 'daily_activity.sedentary_time':
    case 'sleep.awake_time':
    case 'sleep.deep_sleep_duration':
    case 'sleep.latency':
    case 'sleep.light_sleep_duration':
    case 'sleep.rem_sleep_duration':
    case 'sleep.time_in_bed':
    case 'sleep.total_sleep_duration':
      return { type: 'duration', value: secondsToDate(value) };

    case 'sleep.bedtime_end_delta':
    case 'sleep.bedtime_start_delta':
    case 'sleep.midpoint_at_delta':
      return { type: 'timeOfDay', value: secondsToDate(value) };

    case 'daily_activity.equivalent_walking_distance':
      return {
        type: 'distance',
        value: convertDistance(value, locale.units),
        unit: locale.units === 'metric' ? 'kilometer' : 'mile',
      };

    case 'daytime_hr':
    case 'sleep.average_heart_rate':
    case 'sleep.lowest_heart_rate':
      return { type: 'heartRate', value };

    case 'sleep.average_breath':
      return { type: 'breathRate', value };

    case 'daily_activity.average_met_minutes':
      return { type: 'met', value };

    case 'sleep.efficiency':
    case 'daily_spo2_average.spo2_percentage':
      return { type: 'ratio', value };

    case 'sleep.average_hrv':
      return { type: 'heartRateVariability', value };

    case 'daily_readiness.temperature_deviation':
    case 'daily_readiness.temperature_trend_deviation':
      return {
        type: 'temperatureDelta',
        value: convertTemperatureDeviation(locale, value),
        unit: locale.units === 'metric' ? 'celsius' : 'fahrenheit',
      };

    case 'daily_activity.active_calories':
    case 'daily_activity.target_calories':
    case 'daily_activity.total_calories':
      return { type: 'energy', value };

    case 'daily_activity.inactivity_alerts':
      return { type: 'alert', value };

    // Contributors are all scores between [1, 100]
    case 'daily_activity.contributors.meet_daily_targets':
    case 'daily_activity.contributors.move_every_hour':
    case 'daily_activity.contributors.recovery_time':
    case 'daily_activity.contributors.stay_active':
    case 'daily_activity.contributors.training_frequency':
    case 'daily_activity.contributors.training_volume':
    case 'daily_readiness.contributors.activity_balance':
    case 'daily_readiness.contributors.body_temperature':
    case 'daily_readiness.contributors.hrv_balance':
    case 'daily_readiness.contributors.previous_day_activity':
    case 'daily_readiness.contributors.previous_night':
    case 'daily_readiness.contributors.recovery_index':
    case 'daily_readiness.contributors.resting_heart_rate':
    case 'daily_readiness.contributors.sleep_balance':
    case 'daily_sleep.contributors.deep_sleep':
    case 'daily_sleep.contributors.efficiency':
    case 'daily_sleep.contributors.latency':
    case 'daily_sleep.contributors.rem_sleep':
    case 'daily_sleep.contributors.restfulness':
    case 'daily_sleep.contributors.timing':
    case 'daily_sleep.contributors.total_sleep':
      return { type: 'score', value };

    case 'sleep.bedtime_end':
    case 'sleep.bedtime_start':
    case 'sleep.contributors.deep_sleep':
    case 'sleep.contributors.efficiency':
    case 'sleep.contributors.latency':
    case 'sleep.contributors.rem_sleep':
    case 'sleep.contributors.restfulness':
    case 'sleep.contributors.timing':
    case 'sleep.contributors.total_sleep':
    case 'sleep.sleep_midpoint':
      return {
        type: 'unsupported',
        value,
        metric,
      };

    default:
      return assertNever(metric);
  }
}

export function getScale({
  locale,
  metricKey,
  range,
  domain,
}: {
  locale: LocaleData;
  metricKey: MetricKeyExtended;
  range: number[];
  domain: [number, number] | [Date, Date];
}) {
  const sample = toMeasurementValue(locale, metricKey, 0);
  return isDateMeasurementValue(sample)
    ? scaleTime().range(range).domain(domain).nice()
    : scaleLinear().range(range).domain(domain).nice();
}

export type ValueFormatter = (m: MeasurementValue) => string;
export function useFormatMeasurement(): ValueFormatter {
  const intl = useIntl();
  return useCallback(
    (m: MeasurementValue) => formatMeasurement(intl, m),
    [intl],
  );
}

export type TickFormatter = (value: number | Date) => string;
export function useFormatTick(
  locale: LocaleData,
  metricKey: MetricKeyExtended,
): TickFormatter {
  const intl = useIntl();
  const sample = toMeasurementValue(locale, metricKey, 0);
  return function tickFormatter(value: number | Date) {
    return formatMeasurement(intl, unsafeReplaceValue(sample, value));
  };
}

export function unsafeReplaceValue(
  sample: MeasurementValue,
  value: number | Date,
): MeasurementValue {
  switch (sample.type) {
    case 'duration':
    case 'timeOfDay': {
      if (!(value instanceof Date)) {
        return { ...sample, value: new Date(value) };
      }
      return { ...sample, value };
    }
    default: {
      if (typeof value !== 'number') {
        throw new TypeError(
          "Can't replace numeric measurement value with a non-number",
        );
      }
      return { ...sample, value };
    }
  }
}

function getFormattedUnit(unit: string | undefined) {
  switch (unit) {
    case 'millisecond':
      return ' ms';
    case 'kilometer':
      return ' km';
    case 'mile':
      return ' mi';
    case 'celsius':
      return ' °C';
    case 'fahrenheit':
      return ' °F';
    case 'percent':
      return '%';
    default:
      return '';
  }
}

export function formatMeasurement(
  { formatDate, formatNumber: intlFormatNumber, formatMessage }: IntlShape,
  measurement: MeasurementValue,
  extraOptions?: FormatNumberOptions,
): string {
  function t(id: MessageKey, value: string | number) {
    return formatMessage({ id }, { value });
  }

  /**
   * This is a workaround for old browsers that don't support style: 'unit'.
   *
   * https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_unit_parameter
   */
  function formatNumber(value: number, options?: FormatNumberOptions) {
    try {
      return intlFormatNumber(value, options);
    } catch {
      return `${intlFormatNumber(value, {
        ...options,
        style: 'decimal',
      })}${getFormattedUnit(options?.unit)}`;
    }
  }

  switch (measurement.type) {
    case 'alert': {
      // NOTE: Since the alerts' translation uses PluralRules, the value needs to be a number
      const rounded = Number(measurement.value.toFixed(1));
      return t('metric_unit.alerts', rounded);
    }

    case 'breathRate':
      return t(
        'metric_unit.per_minute',
        formatNumber(measurement.value, {
          maximumFractionDigits: 1,
          ...extraOptions,
        }),
      );

    case 'count':
      return formatNumber(measurement.value, {
        maximumFractionDigits: 1,
        ...extraOptions,
      });

    case 'distance':
      return formatNumber(measurement.value, {
        style: 'unit',
        unit: measurement.unit,
        maximumFractionDigits: 1,
        ...extraOptions,
      });

    case 'energy':
      return t(
        'metric_unit.cal',
        formatNumber(measurement.value, {
          maximumFractionDigits: 0,
          ...extraOptions,
        }),
      );

    case 'heartRate':
      return t(
        'metric_unit.bpm',
        formatNumber(measurement.value, {
          maximumFractionDigits: 0,
          ...extraOptions,
        }),
      );

    case 'heartRateVariability':
      return formatNumber(measurement.value, {
        style: 'unit',
        unit: 'millisecond',
        maximumFractionDigits: 0,
        ...extraOptions,
      });

    case 'met':
      return t(
        'metric_unit.average_metabolic_equivalents',
        formatNumber(measurement.value, {
          maximumFractionDigits: 1,
          ...extraOptions,
        }),
      );

    case 'ratio':
      return formatNumber(measurement.value, {
        style: 'unit',
        unit: 'percent',
        maximumFractionDigits: 0,
        ...extraOptions,
      });

    case 'score':
      return formatNumber(measurement.value, {
        maximumFractionDigits: 0,
        ...extraOptions,
      });

    case 'step':
      return formatNumber(measurement.value, {
        maximumFractionDigits: 0,
        ...extraOptions,
      });

    case 'temperatureDelta':
      return formatNumber(measurement.value, {
        style: 'unit',
        unit: measurement.unit,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        signDisplay: 'always',
        ...extraOptions,
      });

    case 'timeOfDay':
      // Our reference is UTC so for displaying we need to offset by the TZ offset
      return formatDate(
        addMinutes(measurement.value, measurement.value.getTimezoneOffset()),
        {
          hour: 'numeric',
          minute: 'numeric',
        },
      );

    case 'duration': {
      const totalSeconds = Math.abs(measurement.value.getTime()) / 1000;

      /**
       * Note: we round the minutes because we don't show seconds in the UI. For hours,
       * we want to use Math.floor because the minutes part shows the minutes "on top of"
       * the hour value.
       */
      const totalMinutes = Math.round(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);

      const id: MessageKey =
        hours > 0
          ? 'metric_unit.duration_hour_minute'
          : 'metric_unit.duration_minute';
      return formatMessage(
        { id },
        {
          hours: formatNumber(hours),
          minutes: formatNumber(totalMinutes % 60),
        },
      );
    }

    case 'unsupported':
      throw new TypeError(
        `Unsupported metric in 'formatMeasurement': ${measurement.metric}`,
      );

    default:
      return assertNever(measurement);
  }
}

export function formatMeasurementDifference(
  intl: IntlShape,
  measurement: MeasurementValue,
): string {
  const n = Number(measurement.value);
  const prefix = intl
    .formatNumber(n > 0 ? 1 : -1, { signDisplay: 'always' })
    .replace('1', '');
  switch (measurement.type) {
    case 'timeOfDay': {
      const duration = formatMeasurement(
        intl,
        {
          type: 'duration',
          value: measurement.value,
        },
        { signDisplay: 'never' },
      );
      return `${prefix}${duration}`;
    }
    case 'duration': {
      const duration = formatMeasurement(intl, measurement, {
        signDisplay: 'never',
      });
      return `${prefix}${duration}`;
    }
    default:
      return formatMeasurement(intl, measurement, { signDisplay: 'always' });
  }
}

export function shouldHideTrendPercentage(locale: LocaleData, key: MetricKey) {
  const measurement = toMeasurementValue(locale, key, 0);
  return (
    measurement.type === 'timeOfDay' ||
    key === 'daily_readiness.temperature_deviation' ||
    key === 'daily_readiness.temperature_trend_deviation'
  );
}

const EXTENT_FALLBACK: [number, number] = [0, 100];

export function extent(
  measurements: Array<MeasurementValue | undefined>,
): [number, number] | [Date, Date] {
  const sample = measurements.find((m) => m != null);
  if (!sample) {
    return EXTENT_FALLBACK;
  }
  const [min, max] = d3Extent(
    measurements
      .map((m) => (m?.value instanceof Date ? m.value.getTime() : m?.value))
      .filter((v): v is number => typeof v === 'number'),
  );
  if (min == null || max == null) {
    return EXTENT_FALLBACK;
  }
  return isDateMeasurementValue(sample)
    ? [new Date(min), new Date(max)]
    : [min, max];
}

export function mean(
  measurements: Array<MeasurementValue | undefined>,
  transform: (value: number | undefined) => number | undefined = (a) => a,
): MeasurementValue | undefined {
  const sample = measurements.find((m) => m != null);
  if (!sample) {
    return undefined;
  }
  const value = transform(
    d3Mean(
      measurements.map((m) =>
        m?.value instanceof Date ? m.value.getTime() : m?.value,
      ),
    ),
  );
  if (value == null || !Number.isFinite(value)) {
    return undefined;
  }
  switch (sample.type) {
    case 'duration':
    case 'timeOfDay':
      return unsafeReplaceValue(sample, new Date(value));
    default:
      return unsafeReplaceValue(sample, value);
  }
}

function secondsToDate(seconds: number) {
  // date at UNIX epoch + seconds
  return new Date(seconds * 1000);
}

function convertDistance(meters: number, units: 'metric' | 'imperial'): number {
  switch (units) {
    case 'metric':
      return meters / 1000;
    case 'imperial':
      return meters * 0.000621371;
    default:
      return assertNever(units);
  }
}

function convertTemperatureDeviation({ units }: LocaleData, deviation: number) {
  switch (units) {
    case 'metric':
      return deviation;
    case 'imperial':
      return deviation * (9 / 5);
    default:
      return assertNever(units);
  }
}

function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}
